import axios from 'axios'

export const businessNeighbourhoodPost = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_BUSINESS_URL}/business/neighbourhood`,
        data
      )
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err)
      })
  })
}
