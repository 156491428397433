import makeStyles from '@mui/styles/makeStyles'
import React, { useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { tables } from '../../../config/tables'
import AuthModal from '../../Auth/AuthModal/AuthModal'

import { GlobalFuncContext } from '../../../Contexts/GlobalFuncHOC'

const style = makeStyles(({ palette }) => {
  return {
    root: {
      position: 'fixed',
      left: 0,
      top: 64,
      background: '#FFFFFF',
      zIndex: 1,
      // eslint-disable-next-line no-useless-computed-key
      ['@media (max-width: 1100px)']: {
        '& ul': {
          display: 'flex',
          width: '100vw',
          padding: 0,
          overflowX: 'scroll',
          textAlign: 'center',
          scrollbarWidth: 'none',
          borderBottom: '1px solid #dfdfdf87',
          '& li': {
            margin: '6px auto'
          },
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }
      },
      '& ul': {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
        whiteSpace: 'nowrap',
        '& li': {
          margin: 5,
          fontWeight: 500,
          width: 200,
          padding: '10px 20px',
          borderRadius: 5,
          cursor: 'pointer',
          '&.active': {
            background: '#F0F3F9'
          }
        }
      }
    },
    btn: {
      display: 'none',
      // eslint-disable-next-line no-useless-computed-key
      ['@media (max-width: 1100px)']: {
        display: 'block'
      }
    }
  }
})

function Sidebar (props) {
  const classes = style()
  const location = useLocation()
  const history = useHistory()
  const { openCreateBusinessPlan } = useContext(GlobalFuncContext)

  const [createBusinessOpen, setCreateBusinessOpen] = useState(false)

  return (
		<div className={classes.root}>
			<ul>
				<li
					onClick={() => history.push('/')}
					className={location.pathname === '/' ? 'active' : ''}
				>
					Home
				</li>
				{tables().map(({ label: { plural }, path }) => (
					<li
						key={path}
						onClick={() => history.push(path)}
						className={location.pathname === path ? 'active' : ''}
					>
						All {plural}
					</li>
				))}
        <li
          onClick={() => history.push('/allReports')}
          className={
            location.pathname === '/allReports' ? 'active' : ''
          }
        >
          All Reports
        </li>
        <li
          onClick={() => history.push('/allBusinessReviews')}
          className={
            location.pathname === '/allBusinessReviews' ? 'active' : ''
          }
        >
          Business Reviews
        </li>
        <li
          onClick={() => history.push('/business_campaign')}
          className={
            location.pathname === '/business_campaign' ? 'active' : ''
          }
        >
          Business Campaign
        </li>
        <li
          // onClick={() => history.push("/createBusiness")}
          onClick={() => setCreateBusinessOpen(true)}
          className={
            location.pathname === '/createBusiness' ? 'active' : ''
          }
        >
          Create Business
        </li>
        <li
          onClick={() => openCreateBusinessPlan(true)}
        >
          Create Business Plan
        </li>
      </ul>
      {createBusinessOpen && (
        <AuthModal
          isOpen={createBusinessOpen}
          onClose={() => setCreateBusinessOpen(false)}
          isLogin={null}
        />
      )}
    </div>
  )
}

export default Sidebar
