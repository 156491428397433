import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'

// importing components
import { UserContext } from '../../../Contexts/UserContext'

// importing api
import { approxGeneral } from 'api/admin/api/approx/general'
import { analyticsActiveUsersDistinct } from 'api/admin/api/activeUser'

const DashHome = () => {
  const [loading, setLoading] = useState(true)
  const approxDataMapper = [
    {
      title: 'Total User Count',
      key: 'userCount'
    },
    {
      title: 'Total Nearcast Count',
      key: 'nearcastCount'
    },
    {
      title: 'Total Group Count',
      key: 'groupCount'
    },
    {
      title: 'Total Gathering Count',
      key: 'gatheringCount'
    },
    {
      title: 'Total Business Count',
      key: 'businessCount'
    },
    {
      title: 'Total Offer Count',
      key: 'offerCount'
    },
    {
      title: 'Total Offer Review Count',
      key: 'offerReviewCount'
    },
    {
      title: 'Total Anonymous Offer Review Count',
      key: 'anonymousOfferReviewCount'
    },
    {
      title: 'How many user created video',
      key: 'peopleMadeVideo'
    }
  ]
  const activeUserMapper = [
    {
      title: 'Daily Active Users',
      key: 'daily'
    },
    {
      title: 'Weekly Active Users',
      key: 'weekly'
    },
    {
      title: 'Monthly Active Users',
      key: 'monthly'
    }
  ]
  const [approxData, setApproxData] = useState({
    businessCount: 0,
    gatheringCount: 0,
    groupCount: 0,
    nearcastCount: 0,
    offerCount: 0,
    offerReviewCount: 0,
    userCount: 0,
    anonymousOfferReviewCount: 0,
    peopleMadeVideo: 0
  })
  const [activeUser, setActiveUser] = useState({
    daily: 0,
    weekly: 0,
    monthly: 0
  })
  const { jwt_token } = useContext(UserContext)

  useEffect(() => {
    approxGeneral(jwt_token)
      .then((resBody) => {
        setApproxData({
          businessCount: resBody.business_count,
          gatheringCount: resBody.gathering_count,
          groupCount: resBody.groups_count,
          nearcastCount: resBody.nearcast_count,
          offerCount: resBody.offer_count,
          offerReviewCount: resBody.offer_review_count,
          userCount: resBody.user_count,
          anonymousOfferReviewCount: resBody.anonymous_offer_review_count,
          peopleMadeVideo: resBody.people_made_video
        })
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))

    // Get active users: daily, weekly, monthly
    const activeUserRequests = [1, 7, 31].map((howManyDaysToSubtract) => {
      return analyticsActiveUsersDistinct(
        dayjs().subtract(howManyDaysToSubtract, 'day').toISOString(),
        dayjs().toISOString(),
        jwt_token
      )
    })
    Promise.all(activeUserRequests)
      .then(([daily, weekly, monthly]) => {
        setActiveUser({
          daily,
          weekly,
          monthly
        })
      })
      .catch((err) => console.log(err.message))
  }, [])

  return (
		<Grid
			container
			direction="row"
			justifyContent="center"
			alignItems="center"
			style={{ width: '100%' }}
		>
			{loading
			  ? (
            <div
              style={{
                display: 'flex',
                height: '70vh',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#FFFFFF'
              }}
            >
              <CircularProgress />
            </div>
			    )
			  : (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ width: '100%' }}
              spacing={4}
            >
              {approxDataMapper.map((dataMapper) => {
                return (
                  <Grid item xs={12} md={6} key={dataMapper.key}>
                    <Card
                      style={{
                        color: '#0C3A2D',
                        backgroundColor: '#FFFFFF',
                        textAlign: 'center',
                        border: '1px solid #0C3A2D'
                      }}
                    >
                      <div>
                        <CardContent style={{ padding: 10 }}>
                          <Typography
                            gutterBottom
                            variant="h6"
                            component="h2"
                            style={{ fontWeight: 'bold' }}
                          >
                            {dataMapper.title}
                          </Typography>
                          <Typography
                            variant="h1"
                            color="textSecondary"
                            component="h1"
                            style={{ color: '#0C3A2D' }}
                          >
                            {approxData[dataMapper.key]}
                          </Typography>
                        </CardContent>
                      </div>
                    </Card>
                  </Grid>
                )
              })}
              {activeUserMapper.map((userMapper) => {
                return (
                  <Grid item xs={12} md={6} key={userMapper.key}>
                    <Card
                      style={{
                        color: '#0C3A2D',
                        backgroundColor: '#FFFFFF',
                        textAlign: 'center',
                        border: '1px solid #0C3A2D'
                      }}
                    >
                      <div>
                        <CardContent style={{ padding: 10 }}>
                          <Typography
                            gutterBottom
                            variant="h6"
                            component="h2"
                            style={{ fontWeight: 'bold' }}
                          >
                            {userMapper.title}
                          </Typography>
                          <Typography
                            variant="h1"
                            color="textSecondary"
                            component="h1"
                            style={{ color: '#0C3A2D' }}
                          >
                            {activeUser[userMapper.key]}
                          </Typography>
                        </CardContent>
                      </div>
                    </Card>
                  </Grid>
                )
              })}
            </Grid>
			    )}
		</Grid>
  )
}

export default DashHome
