import axios from 'axios'

export type SubscriptionType = {
  admin_id: string,
  admin_name: string,
  plan: string,
  amount: number,
  currency: string,
  is_coupon_applicable: boolean,
  subscription_span: number,
  plan_details: Object
}

/**
 * @function subscriptionPlanCreate 
 * @param {SubscriptionType} subscription - subscription plan
 * @param {string} jwt - the JWT token of the user (accessed through UserContext)
 * @returns {Promise} Promise object represents true
 */
export const subscriptionPlanCreate = (subscription: SubscriptionType, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/admin/api/subscription-plan`,
      subscription,
      {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    )
      .then(() => resolve(true))
      .catch((err) => reject(err))
  })
}
