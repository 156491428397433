import axios from 'axios'

export const adminBusinessAnalyticsDetails = (startTime, endTime, page = 1, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/admin/api/business/businesses-analytics-details`, {
      from: startTime,
      to: endTime
    }, {
      params: {
        page
      },
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => {
        reject(err)
      })
  })
}

export const adminBusinessAnalyticsModal = (businessId, startTime, endTime, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/admin/api/business-offer-analytics/business-modal`, {
      business_id: businessId,
      from: startTime,
      to: endTime
    }, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err)
      })
  })
}
