import { Button, Typography } from '@mui/material'
import React, { useState, useContext } from 'react'
import StandardModal from 'ui/Modals/StandardModal'
import TextInput from 'ui/TextInputs/PriceFieldsInput'
import { changeCustomUrlPost } from 'api/admin/api/business/customUrl'
import { UserContext } from '../../../Contexts/UserContext'

const EditCustomURL = ({ open, onClose, data }) => {
  const { jwt_token } = useContext(UserContext)

  const [url, setUrl] = useState(data.custom_url)
  const [btnLoading, setBtnLoading] = useState(false)

  const changeUrl = async () => {
    setBtnLoading(true)
    try {
      await changeCustomUrlPost(jwt_token, data.id, url)
      window.location.reload() // TODO: UPDATE WITHOUT RELOAD
    } catch (error) {
      // console.log(error);
      setBtnLoading(false)
    }
  }

  return (
		<StandardModal isOpen={open} onClose={onClose} title="Edit Custom URL">
			<div style={{ padding: '10px 0' }}>
				<Typography fontSize="14px" marginLeft="5px">
					Changing the URL of: <strong>{data.name}</strong>
				</Typography>
				<TextInput
					value={url}
					onChange={(e) => setUrl(e.target.value)}
					style={{ width: '95%' }}
				/>
				<div
					style={{
					  display: 'flex',
					  justifyContent: 'center',
					  marginTop: '20px'
					}}
				>
					<Button
						variant="contained"
						onClick={changeUrl}
						disabled={btnLoading}
					>
						Change URL
					</Button>
				</div>
			</div>
		</StandardModal>
  )
}

export default EditCustomURL
