import axios from 'axios'

/**
 * @function adminApiAnalyticsBusinessCampaign Used to login to the admin portal
 * @param {string} jwt - the jwt
 * @returns {Promise} Promise object represents
 */

export const adminApiAnalyticsBusinessCampaign = (from, to, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/admin/api/business/business-campaign`, {
      params: {
        from: from,
        to: to
      },
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => reject(err))
  })
}
