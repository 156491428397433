import {
  CircularProgress,
  FormControl,
  Grid,
  TextField
} from '@mui/material'
import { useState } from 'react'
import firebase from '../../../config/firebase-business'
import { Mail } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { signupEmail } from 'api/signup/email'

function EmailSignup ({ onSuccess, onError, chosen, setChosen }) {
  const [loading, setLoading] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { enqueueSnackbar } = useSnackbar()

  const emailSignupFirebase = () => {
    setLoading(true)
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            onSuccess(token)
            setLoading(false)
          })
      })
      .catch((error) => {
        // console.log(error.code);
        if (error.code === 'auth/email-already-in-use') {
          emailLogin()
        } else if (error.code === 'auth/weak-password') {
          enqueueSnackbar('Weak Password! Atleast 6 characters', {
            variant: 'error'
          })
          setLoading(false)
        } else if (error.code === 'auth/invalid-email') {
          enqueueSnackbar('Email not Supported', {
            variant: 'error'
          })
          setLoading(false)
        } else {
          onError()
          setLoading(false)
        }
      })
  }

  const emailSignup = async () => {
    if (name.trim().length === 0) {
      enqueueSnackbar('Please enter your name.', {
        variant: 'error'
      })

      return
    }

    setLoading(true)
    signupEmail(email, name, password)
      .then((res) => {
        emailSignupFirebase()
      })
      .catch((error) => {
        onError()
        setLoading(false)
      })
  }

  const emailLogin = (e) => {
    setLoading(true)
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            onSuccess(token)
            setLoading(false)
          })
      })
      .catch((error) => {
        if (error.code === 'auth/user-not-found') {
          enqueueSnackbar('User Not Found!', { variant: 'error' })
          setNotFound(true)
          setLoading(false)
        } else if (error.code === 'auth/wrong-password') {
          enqueueSnackbar('Invalid Password', { variant: 'error' })
          setLoading(false)
        } else {
          onError()
          setLoading(false)
        }
      })
  }

  return (
      <>
        {chosen
          ? (
          <>
            {chosen && notFound && (
              <Grid item>
                <FormControl fullWidth>
                  <TextField
                    label="Name"
                    id="name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value)
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  label="Email"
                  id="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  label="Password"
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value)
                  }}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
          </>
            )
          : (
          <></>
            )}
        <Grid item>
          <FormControl fullWidth>
            <button
              onClick={
                chosen
                  ? emailLogin
                  : () => {
                      setChosen(true)
                    }
              }
              style={{
                background: '#0C3A2D',
                borderRadius: 10,
                color: '#FFFFFF',
                border: 'none',
                display: 'inline',
                padding: '14px',
                cursor: 'pointer',
                fontSize: 16,
                margin: 2
              }}
              disabled={loading}
            >
              {loading
                ? (
                <CircularProgress
                  size={18}
                  style={{
                    verticalAlign: 'middle',
                    marginRight: 10,
                    color: '#FFFFFF'
                  }}
                />
                  )
                : (
                <Mail
                  style={{
                    verticalAlign: 'middle',
                    marginRight: 10,
                    height: 16,
                    position: 'absolute',
                    left: 24
                  }}
                />
                  )}
              Continue with Email
            </button>
          </FormControl>
        </Grid>
        {chosen && notFound
          ? (
          <Grid item>
            <FormControl fullWidth>
              <button
                onClick={emailSignup}
                style={{
                  background: '#C04F27',
                  borderRadius: 10,
                  color: '#FFFFFF',
                  border: 'none',
                  display: 'inline',
                  padding: '14px',
                  cursor: 'pointer',
                  fontSize: 16,
                  margin: 2
                }}
                disabled={loading}
              >
                {loading
                  ? (
                  <CircularProgress
                    size={18}
                    style={{
                      verticalAlign: 'middle',
                      marginRight: 10,
                      color: '#FFFFFF'
                    }}
                  />
                    )
                  : (
                  <Mail
                    style={{
                      verticalAlign: 'middle',
                      marginRight: 10,
                      height: 16,
                      position: 'absolute',
                      left: 24
                    }}
                  />
                    )}
                Create Account
              </button>
            </FormControl>
          </Grid>
            )
          : (
          <></>
            )}
      </>
  )
}

export default EmailSignup
