import axios from 'axios'

/**
 * @function adminGetAll Gets all items of a paricular type (eg. all nearcasts, all groups, etc.)
 * @param {string} getAll - a string representing the type of item to get (eg. "nearcasts", "groups", etc.)
 * @param {string} jwt - the JWT token of the user (accessed through UserContext)
 * @param {string} params - query params for the request (eg. ?page=1)
 * @returns {Promise} Promise object represents
 */

export const adminGetAll = (getAll, jwt, params) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/admin/api/${getAll}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`
        },
        params: params
      }
    )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  })
}

export const adminGetOne = (getOne, jwt) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_ADMIN_URL}/admin/api/${getOne}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }
      )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  })
}
