import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@mui/material'
import { ArrowBackIos } from '@mui/icons-material'
import axios from 'axios'
import { UserContext } from '../../../Contexts/UserContext'
import { useSnackbar } from 'notistack'
import { AuthLayout } from './AuthPopup'
import EmailSignup from './EmailSignup'
import { signupInit } from 'api/signup/init'

function MergedContent ({ pathname, business, hideSignUp }) {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [idToken, setIdToken] = useState('')
  const { login } = useContext(UserContext)
  const [chosenEmail, setChosenEmail] = useState(false)

  const errorFunction = () =>
    enqueueSnackbar('Please try again', {
      variant: 'error'
    })

  const checkExist = async (token) => {
    signupInit('', token).then((res) => {
      if (hideSignUp) {
        enqueueSnackbar('Claim your Business Page', {
          variant: 'info'
        })
        history.push('/create')
      } else {
        setIdToken(token)
      }
    })
  }

  const addAccount = async (data) => {
    axios
      .post(
				`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/personal_account/add`,
				business,
				{
				  headers: {
				    'NRCT-UID': data.personal_accounts[0].ID,
				    Authorization: `Bearer ${data.jwtToken}`
				  }
				}
      )
      .then((res) => {
        enqueueSnackbar('Business Account Created', {
          variant: 'success'
        })
      })
      .catch((err) => {
        let error
        if (
          err.response &&
					err.response.data &&
					err.response.data.msg
        ) {
          error = err.response.data.msg
        } else {
          error = 'Please Try Again'
        }
        enqueueSnackbar(error, { variant: 'error' })
      })
  }

  const signupApi = async () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/signup`, {
        ...business,
        id_token: idToken
      })
      .then((res) => {
        if (res.data) {
          enqueueSnackbar('Business Registered!', {
            variant: 'success'
          })
        }
      })
      .catch((err) => {
        enqueueSnackbar('Could not sign up. Please try again!', {
          variant: 'error'
        })
      })
  }

  useEffect(() => {
    if (idToken !== '') signupApi()
  }, [idToken])

  return (
		<>
			<AuthLayout title="Choose Signin Option">
				<>
					{chosenEmail
					  ? (
						<Button
							style={{ width: 100, height: 40, marginBottom: 20 }}
							onClick={() => setChosenEmail(false)}
						>
							<ArrowBackIos /> Back
						</Button>
					    )
					  : null}
					<EmailSignup
						onSuccess={checkExist}
						chosen={chosenEmail}
						setChosen={setChosenEmail}
						onError={errorFunction}
					/>
					<div style={{ marginTop: 'auto' }}></div>
				</>
			</AuthLayout>
		</>
  )
}

export default MergedContent
