
export const getUserDomainURL = (route: string): string => {
  const origin = window.location.origin
  if (origin.includes('staging') || origin.includes('localhost')) {
    return `https://staging.nearcast.com${route}`
  } else {
    return `https://nearcast.com${route}`
  }
}

export const getBusinessDomainURL = (route: string): string => {
  const origin = window.location.origin
  if (origin.includes('staging') || origin.includes('localhost')) {
    return `https://stagingbusiness.nearcast.com${route}`
  } else {
    return `https://business.nearcast.com${route}`
  }
}

export const getUserDomainPreviewUrl = (route: string): string => {
  const origin = window.location.origin
  if (origin.includes('staging') || origin.includes('localhost')) {
    return `https://dev.nearca.st${route}`
  } else {
    return `https://nearca.st${route}`
  }
}
