import React from 'react'

// importing component
import RespondToFeatureRequest from '../Components/modals/RespondToFeatureRequest'
import AddMusicAuthorModal from '../Components/modals/AddMusicAuthor'
import AddMusicModal from '../Components/modals/AddMusic'
import BusinessOfferDetails from '../Components/modals/BusinessOfferDetails/BusinessOfferDetails'
import CreateBusinessPlan from '../Components/modals/CreateBusinessPlan/CreateBusinessPlan'

export interface GlobalFuncContextValueType {
  openRespondToFeature: (
    featureId: string
  ) => void,
  openAddMusicAuthor: (
    neighborhoodId?: string,
    onSuccess?: () => void,
   ) => void,
  openAddMusic: (
    neighborhoodId?: string,
  ) => void,
  openBusinessOfferDetails: (
    businessId: string,
    startTime: string,
    endTime: string
  ) => void,
  openCreateBusinessPlan: () => void
}

const GlobalFuncContextValueInitial: GlobalFuncContextValueType = {
  openRespondToFeature: (fId: string) => {},
  openAddMusicAuthor: (nId?: string) => {},
  openAddMusic: (nId?: string) => {},
  openBusinessOfferDetails: (bId: string, st: string, et: string) => {},
  openCreateBusinessPlan: () => {}
}

export const GlobalFuncContext = React.createContext(GlobalFuncContextValueInitial)

const GlobalFuncHOC = ({ children }) => {
  // state for share offer modal
  const respondToFeatureInitial = {
    isOpen: false,
    featureId: ''
  }
  const [respondToFeature, setRespondToFeature] = React.useState(respondToFeatureInitial)
  // state for add music author modal
  const addMusicAuthorInitial = {
    isOpen: false,
    neighborhoodId: undefined,
    onSuccess: undefined
  }
  const [addMusicAuthor, setAddMusicAuthor] = React.useState(addMusicAuthorInitial)
  // state for add music
  const addMusicInitial = {
    isOpen: false,
    neighborhoodId: undefined
  }
  const [addMusic, setAddMusic] = React.useState(addMusicInitial)
  // state for business offer details
  const businessOfferDetailsInitial = {
    businessId: undefined,
    startTime: undefined,
    endTime: undefined,
    isOpen: false
  }
  const [businessOfferDetails, setBusinessOfferDetails] = React.useState(businessOfferDetailsInitial)
  // state for create business plan
  const [createBusinessPlanOpen, setCreateBusinessPlanOpen] = React.useState(false)

  const value: GlobalFuncContextValueType = {
    openRespondToFeature: (fId) => {
      setRespondToFeature({
        isOpen: true,
        featureId: fId
      })
    },
    openAddMusicAuthor: (nId = undefined, os = undefined) => {
      setAddMusicAuthor({
        isOpen: true,
        neighborhoodId: nId,
        onSuccess: os
      })
    },
    openAddMusic: (nId = undefined, os = undefined) => {
      setAddMusic({
        isOpen: true,
        neighborhoodId: nId
      })
    },
    openBusinessOfferDetails: (bId, st, et) => {
      setBusinessOfferDetails({
        businessId: bId,
        startTime: st,
        endTime: et,
        isOpen: true
      })
    },
    openCreateBusinessPlan: () => setCreateBusinessPlanOpen(true)
  }

  return (
    <GlobalFuncContext.Provider value={value}>
      {children}
      <RespondToFeatureRequest
        isOpen={respondToFeature.isOpen}
        onClose={() => setRespondToFeature(respondToFeatureInitial)}
        featureId={respondToFeature.featureId}
      />
      <AddMusicAuthorModal
        isOpen={addMusicAuthor.isOpen}
        onClose={() => setAddMusicAuthor(addMusicAuthorInitial)}
        neighborhoodId={addMusicAuthor.neighborhoodId}
        onSuccess={addMusicAuthor.onSuccess}
      />
      <AddMusicModal
        isOpen={addMusic.isOpen}
        onClose={() => setAddMusic(addMusicInitial)}
        neighborhoodId={addMusic.neighborhoodId}
      />
      <BusinessOfferDetails
        businessId={businessOfferDetails.businessId}
        startTime={businessOfferDetails.startTime}
        endTime={businessOfferDetails.endTime}
        isOpen={businessOfferDetails.isOpen}
        onClose={async () => setBusinessOfferDetails(businessOfferDetailsInitial)}
      />
      <CreateBusinessPlan
        isOpen={createBusinessPlanOpen}
        onClose={() => setCreateBusinessPlanOpen(false)}
      />
    </GlobalFuncContext.Provider>
  )
}

export default GlobalFuncHOC
