import * as React from 'react'
import {
  Stack,
  Button,
  FormControl,
  Typography,
  Select,
  MenuItem
} from '@mui/material'
import { Add } from '@mui/icons-material'
import axios from 'axios'

// importing ui
import StandardModal from 'ui/Modals/StandardModal'
import { TextLabel } from 'ui/TextInputs/TextInput'
import CustomPriceFields from 'ui/TextInputs/PriceFieldsInput'
import ImagePicker from 'ui/Custom/ImagePicker/ImagePicker'
import CropImageModal from 'ui/Custom/Modals/CropImageModal'

// importing api
import { neighbourhoodMusicAuthorsGet } from 'api/admin/api/neighbourhood/neighbourhoodMusicAuthors'
import { musicPresignGet } from 'api/admin/api/music/musicPresign'
import { musicAddPost } from 'api/admin/api/music/musicAdd'

// importing components
import { UserContext } from '../../Contexts/UserContext'
import { GlobalFuncContext } from '../../Contexts/GlobalFuncHOC'
import { Box } from '@mui/system'

// importing hooks
import useDebounce from 'hooks/useDebounce'

export const genres = [
  'Ambient',
  'EDM',
  'Electric Pop',
  'Fusion',
  'HipHop',
  'Instrumental',
  'Jazz',
  'Pop',
  'Pop Func',
  'R&B',
  'Rock',
  'Soul',
  'Sound Track',
  'World Music'
]

export const moods = [
  'Happy',
  'Reflective',
  'Calm',
  'Energetic',
  'Excited',
  'Anxious',
  'Party',
  'Love',
  'Dark',
  'Sad'
]

export interface AddMusicModalProps {
  isOpen: boolean;
  onClose: () => void;
  neighborhoodId?: string;
  onSuccess?: () => void;
}

const AddMusicModal: React.FC<AddMusicModalProps> = ({
  isOpen,
  onClose,
  neighborhoodId,
  onSuccess = () => {}
}) => {
  const { jwt_token } = React.useContext(UserContext)
  const { openAddMusicAuthor } = React.useContext(GlobalFuncContext)
  const [neighborhood, setNeighborhood] = React.useState('')
  const debouncedNeighborhood = useDebounce(neighborhood, 500)
  const [authors, setAuthors] = React.useState<{value: string, label: string}[]>([])

  const [author, setAuthor] = React.useState('')
  const [songName, setSongName] = React.useState('')
  const [isrc, setIsrc] = React.useState('')
  const [genre, setGenre] = React.useState('')
  const [mood, setMood] = React.useState([])
  const [isCropImageModalOpen, setIsCropImageModalOpen] = React.useState<File | null>(null)
  const [musicCover, setMusicCover] = React.useState<File | null>(null)
  const [musicTrack, setMusicTrack] = React.useState<File | null>(null)
  const inputErrorInitial = {
    neighborhoodId: null,
    author: null,
    songName: null,
    isrc: null,
    genre: null,
    mood: null,
    musicCover: null,
    musicTrack: null
  }
  const [inputErrors, setInputErrors] = React.useState(inputErrorInitial)
  const [isCreating, setIsCreating] = React.useState(false)

  const _onClose = () => {
    setInputErrors(inputErrorInitial)
    setNeighborhood('')
    setAuthor('')
    setSongName('')
    setIsrc('')
    setGenre('')
    setMood([])
    setMusicCover(null)
    setMusicTrack(null)
    setIsCreating(false)
    onClose()
  }

  const validate = () => {
    const newInputErrors = inputErrorInitial

    if (neighborhood.length === 0) {
      newInputErrors.neighborhoodId = 'Neighborhood Id can not be empty!'
    }

    if (author.length === 0) {
      newInputErrors.author = 'Author name can not be empty!'
    }

    if (songName.length === 0) {
      newInputErrors.songName = 'Song name can not be empty!'
    }

    if (isrc.length === 0) {
      newInputErrors.isrc = 'ISRC can not be empty!'
    }

    if (genre.length === 0) {
      newInputErrors.genre = 'Please select one genre!'
    }

    if (mood.length === 0) {
      newInputErrors.mood = 'Please select one mood!'
    }

    if (musicCover === null) {
      newInputErrors.musicCover = true
    }

    if (musicTrack === null) {
      newInputErrors.musicTrack = true
    }

    setInputErrors(newInputErrors)
    if (Object.values(newInputErrors).find((v) => v !== null)) {
      return false
    } else {
      return true
    }
  }

  const onSubmit = () => {
    if (!validate()) {
      return
    }

    setIsCreating(true)
    let resDataHolder: any = {}
    musicPresignGet(jwt_token)
      .then((resData) => {
        resDataHolder = resData
        return Promise.all([
          axios.put(resData.body.coverUrl, musicCover),
          axios.put(resData.body.trackUrl, musicTrack)
        ])
      })
      .then(() => musicAddPost(jwt_token, {
        cover: resDataHolder.body.cover,
        track: resDataHolder.body.track,
        id: resDataHolder.body.id,
        neighbourhood_id: neighborhood,
        music_author_id: author,
        song_name: songName,
        genre: genre,
        isrc_number: isrc,
        mood: mood.join(', ')
      }))
      .then(() => {
        _onClose()
      })
      .catch((err) => {
        console.log(err.message)
        setIsCreating(false)
      })
  }

  const getAuthors = async () => {
    if (debouncedNeighborhood.length === 0) {
      return
    }
    neighbourhoodMusicAuthorsGet(jwt_token, debouncedNeighborhood)
      .then((resData) => {
        // if no author exists
        if (!resData?.body) {
          return setAuthors([
            { value: '', label: 'No Authors found in neighbourhood' }
          ])
        }

        setAuthors(
          resData.body.map(({ id, name }) => ({
            value: id,
            label: name
          }))
        )
      })
      .catch((err) => {
        console.log(err.message)
        setAuthors([{ value: '', label: 'Invalid Neighbourhood ID' }])
      })
  }

  React.useEffect(() => {
    if (neighborhoodId) {
      setNeighborhood(neighborhoodId)
    }
  }, [neighborhoodId])

  React.useEffect(() => {
    if (debouncedNeighborhood.length > 0) {
      getAuthors()
    }
  }, [debouncedNeighborhood])

  return (
    <StandardModal
      isOpen={isOpen}
      onClose={_onClose}
      title='Add new Music'
    >
      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        style={{
          width: '100%',
          height: '100%'
        }}
      >
        <FormControl fullWidth margin="dense">
          <CustomPriceFields
            label="NeighborHood Id"
            placeholder="Id of the neighborhood to add this author too"
            value={neighborhood}
            inputProps={{ maxLength: '128' }}
            onChange={(e) => setNeighborhood(e.target.value)}
            style={{
              borderRadius: 10
            }}
            error={inputErrors.neighborhoodId}
          />
          {inputErrors.neighborhoodId && (
            <Typography variant="caption" color="error">
              {inputErrors.neighborhoodId}
            </Typography>
          )}
        </FormControl>
        <FormControl fullWidth margin="dense">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <TextLabel style={{ marginLeft: 6, marginBottom: 4 }}>
              Select Author
            </TextLabel>
            <Button onClick={() => openAddMusicAuthor(neighborhood, getAuthors)}>
              Add Author
            </Button>
          </Stack>
          <Select
            labelId="Author"
            value={author}
            label="Author"
            onChange={(e) => setAuthor(e.target.value)}
            size="small"
          >
            {authors.map((author) => {
              return (
                <MenuItem
                  key={author.value}
                  value={author.value}
                >
                  {author.label}
                </MenuItem>
              )
            })}
          </Select>
          {inputErrors.author && (
            <Typography variant="caption" color="error">
              {inputErrors.author}
            </Typography>
          )}
        </FormControl>
        <FormControl fullWidth margin="dense">
          <CustomPriceFields
            label="Song Name"
            placeholder="The name of the song"
            value={songName}
            inputProps={{ maxLength: '128' }}
            onChange={(e) => setSongName(e.target.value)}
            style={{
              borderRadius: 10
            }}
            error={inputErrors.neighborhoodId}
          />
          {inputErrors.songName && (
            <Typography variant="caption" color="error">
              {inputErrors.songName}
            </Typography>
          )}
        </FormControl>
        <FormControl fullWidth margin="dense">
          <CustomPriceFields
            label="ISRC Number"
            placeholder=""
            value={isrc}
            inputProps={{ maxLength: '256' }}
            onChange={(e) => setIsrc(e.target.value)}
            style={{
              borderRadius: 10
            }}
            error={inputErrors.neighborhoodId}
          />
          {inputErrors.isrc && (
            <Typography variant="caption" color="error">
              {inputErrors.isrc}
            </Typography>
          )}
        </FormControl>
        <FormControl fullWidth margin="dense">
          <TextLabel style={{ marginLeft: 6, marginBottom: 4 }}>
            Select Genre
          </TextLabel>
          <Select
            labelId="Genre"
            value={genre}
            label="Genre"
            onChange={(e) => setGenre(e.target.value)}
            size="small"
          >
            {genres.map((genre) => {
              return (
                <MenuItem
                  key={genre}
                  value={genre}
                >
                  {genre}
                </MenuItem>
              )
            })}
          </Select>
          {inputErrors.genre && (
            <Typography variant="caption" color="error">
              {inputErrors.genre}
            </Typography>
          )}
        </FormControl>
        <FormControl fullWidth margin="dense">
          <TextLabel style={{ marginLeft: 6, marginBottom: 2 }}>
            Select Mood
          </TextLabel>
          <Select
            labelId="Mood"
            multiple
            value={mood}
            label="Mood"
            onChange={(e) => {
              if (typeof e.target.value === 'string') {
                setMood(e.target.value.split(','))
              } else {
                setMood(e.target.value)
              }
            }}
            size="small"
          >
            {moods.map((mood) => {
              return (
                <MenuItem
                  key={mood}
                  value={mood}
                >
                  {mood}
                </MenuItem>
              )
            })}
          </Select>
          {inputErrors.mood && (
            <Typography variant="caption" color="error">
              {inputErrors.mood}
            </Typography>
          )}
        </FormControl>
        <FormControl
          fullWidth
          margin="dense"
          style={{
            borderRadius: 8,
            borderColor: 'red',
            borderWidth: 1,
            borderStyle: inputErrors.musicCover ? 'solid' : 'none'
          }}
        >
          <TextLabel style={{ marginLeft: 6 }}>
            Music Cover {musicCover ? `(${musicCover.name})` : ''}
          </TextLabel>
          <ImagePicker
            onChange={(e) => setIsCropImageModalOpen(e.target.files[0])}
          >
            <Box
              sx={(theme) => ({
                background: theme.palette.primary.main,
                borderRadius: 1,
                cursor: 'pointer',
                width: 40,
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '8px'
              })}
            >
              <Add htmlColor="white" />
            </Box>
          </ImagePicker>
        </FormControl>
        <FormControl
          fullWidth
          margin="dense"
          style={{
            borderRadius: 8,
            borderColor: 'red',
            borderWidth: 1,
            borderStyle: inputErrors.musicTrack ? 'solid' : 'none'
          }}
        >
          <TextLabel style={{ marginLeft: 6 }}>
            Music Track {musicTrack ? `(${musicTrack.name})` : ''}
          </TextLabel>
          <ImagePicker
            onChange={(e) => setMusicTrack(e.target.files[0])}
            accept="audio/*"
          >
            <Box
              sx={(theme) => ({
                background: theme.palette.primary.main,
                borderRadius: 1,
                cursor: 'pointer',
                width: 40,
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '8px'
              })}
            >
              <Add htmlColor="white" />
            </Box>
          </ImagePicker>
        </FormControl>
        <Button
          color='primary'
          variant="contained"
          style={{
            width: '100%',
            marginTop: 18
          }}
          onClick={onSubmit}
          disabled={isCreating}
        >
          {isCreating ? 'Creating' : 'Create'}
        </Button>
      </Stack>
      <CropImageModal
        open={Boolean(isCropImageModalOpen)}
        imageFile={isCropImageModalOpen}
        onCropComplete={(imgBlog) => {
          const imgFile = new File([imgBlog], isCropImageModalOpen.name)
          setMusicCover(imgFile)
          setIsCropImageModalOpen(null)
        }}
      />
    </StandardModal>
  )
}

export default AddMusicModal
