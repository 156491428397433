import React from 'react'

// importing base components
import TextInput, { TextLabel } from './TextInput'

export interface PriceFieldsInputProps {
  label: string;
}

const PriceFieldsInput: React.FC<PriceFieldsInputProps & any> = ({ label, ...props }) => (
  <>
    <TextLabel
      style={{
        marginLeft: 6,
        fontSize: 14,
        fontWeight: 600
      }}
    >
      {label}
    </TextLabel>
    <TextInput color="#8aa6de" {...props} />
  </>
)

export default PriceFieldsInput
