import { createContext, FC, useContext, useState } from 'react'
import classes from './Modal.module.css'

const ModalContext = createContext()

export const ModalProvider = ({ children }) => {
  const [{ Component }, setModalComponent] = useState({
    Component: undefined
  })
  const closeModal = () => setModalComponent({ Component: undefined })
  const openModal = (Component) => setModalComponent({ Component })
  return (
    <ModalContext.Provider
      value={{
        Component: Component,
        openModal,
        closeModal
      }}
    >
      <>
        {Component
          ? (
          <>
            <div className={classes.backdrop} onClick={closeModal}></div>
            <div className={classes.container}>
              <div className={classes.innercontainer}>
                <div className={classes.component}>
                  <Component />
                </div>
              </div>
            </div>
          </>
            )
          : (
          <></>
            )}
        {children}
      </>
    </ModalContext.Provider>
  )
}

export const useModal = () => useContext(ModalContext)
