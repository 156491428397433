import axios from 'axios'

/**
 * @function adminBusinessAnalyticsTotalScans Used to fetch all offer scan event from given timestamps
 * @param {string} startTime - iso string of the start time from when needs to be fetched
 * @param {string} endTime - iso string of the end time till when data needs to be fetched
 * @param {string} jwt - user's jwt token
 * @returns {Promise} resolves to list of scans
 */
export const adminBusinessAnalyticsTotalScans = (startTime, endTime, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/admin/api/business-analytics/scans`, {
      from: startTime,
      to: endTime
    }, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => {
        reject(err)
      })
  })
}

export const adminBusinessAnalyticsTotalViews = (startTime, endTime, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/admin/api/business-analytics/reviews-views`, {
      from: startTime,
      to: endTime
    }, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => {
        reject(err)
      })
  })
}

export const adminBusinessAnalyticsTotalCreated = (startTime, endTime, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/admin/api/business-analytics/reviews-created`, {
      from: startTime,
      to: endTime
    }, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => {
        reject(err)
      })
  })
}

export const adminBusinessAnalyticsTotalOfferCreated = (startTime, endTime, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/admin/api/business-analytics/business-offer-created`, {
      from: startTime,
      to: endTime
    }, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => {
        reject(err)
      })
  })
}
