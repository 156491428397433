import { useEffect, useState } from 'react'
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { Close } from '@mui/icons-material'
import MapDisplay from './MapDisplay'
import MergedContent from './MergedContent'

export function AuthLayout ({ children, title, bottom }) {
  const theme = useTheme()
  return <>
    <div
      style={{
        fontSize: '1.4rem',
        fontWeight: 600,
        padding: 12
      }}
    >
      {title}
    </div>
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={1}
      style={{
        maxWidth: '100%',
        width: 500,
        margin: 'auto',
        height: '100%',
        paddingBottom: 10
      }}
    >
      {children}
      {bottom
        ? (
        <Grid
          item
          style={{
            fontSize: '1rem',
            fontWeight: 400,
            marginTop: 10,
            marginBottom: 10,
            color: theme.palette.primary.main
          }}
        >
          {bottom}
        </Grid>
          )
        : (
        <></>
          )}
    </Grid>
  </>
}

function AuthPopup ({ open, setOpen, pathname, hideSignUp, business }) {
  // const [loginScreen, setLoginScreen] = useState(true);
  const [map, setMap] = useState(true)
  // const history = useHistory();
  // const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'))

  const closeMap = () => {
    setMap(false)
  }

  useEffect(() => {
    setMap(true)
  }, [business])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        style: {
          width: 600,
          borderRadius: 10,
          textAlign: 'center'
        }
      }}
    >
      <DialogContent style={{ padding: 12 }}>
        <IconButton
          onClick={() => setOpen(false)}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            backgroundColor: '#ADADAD',
            fontSize: '0.7rem',
            padding: 5
          }}
          size="large">
          <Close style={{ fontSize: '0.9rem' }} htmlColor="#FFFFFF" />
        </IconButton>
        {business && map
          ? (
          <MapDisplay {...{ business, action: closeMap }} />
            )
          : (
          <MergedContent
            {...{
              pathname,
              business,
              hideSignUp
            }}
          />
            )}
      </DialogContent>
    </Dialog>
  )
}

export default AuthPopup
