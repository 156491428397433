import React from 'react'
import customTheme from './theme'
import { Slide } from '@mui/material'
import { ThemeProvider as DefaultThemeProvider, StyledEngineProvider } from '@mui/material/styles'

// provider for custom use case
import { SnackbarProvider } from 'notistack'

const ThemeProvider = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={1000 * 3}
      preventDuplicate
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      TransitionComponent={Slide}
    >
      <StyledEngineProvider injectFirst>
          <DefaultThemeProvider theme={customTheme} >
              {children}
          </DefaultThemeProvider>
      </StyledEngineProvider>
    </SnackbarProvider>
  )
}

export default ThemeProvider
