import React, { useEffect, useState, useCallback, useContext } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { CustomNoRowsOverlay } from '../GetAll'
import { useParams } from 'react-router-dom'
import { businessDetailsGet } from 'api/admin/api/business/getOne'
import { UserContext } from '../../../Contexts/UserContext'

const OffersView = () => {
  const { jwt_token } = useContext(UserContext)
  const [data, setData] = useState([])
  const [page, setPage] = useState(0)
  const [maxCount, setMaxCount] = useState(0)

  const [loading, setLoading] = useState(true)

  const { business_id } = useParams()

  const limit = 20

  const columns = [
    {
      field: 'sno',
      headerName: 'S.No',
      minWidth: 90,
      align: 'left'
    },
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 300,
      align: 'left'
    },
    {
      field: 'nearcoins',
      headerName: 'Nearcoins',
      minWidth: 120,
      align: 'left'
    },
    {
      field: 'start_date',
      headerName: 'Time Active',
      minWidth: 200,
      align: 'left',
      renderCell: (entry) => (
				<div style={{ fontSize: '0.8rem' }}>
					<strong>From:</strong>{' '}
					{new Date(entry.row.start_date).toLocaleString()}
					<br />
					<strong>To:</strong>{' '}
					{new Date(entry.row.end_date).toLocaleString()}
				</div>
      )
    },
    {
      field: 'is_active',
      headerName: 'Is Active?',
      minWidth: 100,
      align: 'center',
      renderCell: (entry) => (
				<span
					style={{
					  fontWeight: 'bold',
					  color: entry.row.is_active ? 'green' : 'red'
					}}
				>
					{entry.row.is_active ? 'YES' : 'NO'}
				</span>
      )
    },
    {
      field: 'is_in_store_offer',
      headerName: 'Is In Store?',
      minWidth: 130,
      align: 'center',
      renderCell: (entry) => (
				<span
					style={{
					  fontWeight: 'bold',
					  color: entry.row.is_in_store_offer ? 'green' : 'red'
					}}
				>
					{entry.row.is_in_store_offer ? 'YES' : 'NO'}
				</span>
      )
    }
  ]

  const getData = useCallback(async () => {
    try {
      const res = await businessDetailsGet(jwt_token, business_id)
      setMaxCount(res.body.business_offers_data.length)
      const offers = res.body.business_offers_data.map((item, i) => ({
        ...item,
        sno: i + 1
      }))

      setData(offers.filter((item) => item.id !== null))
    } catch (error) {
      // console.log(error);
    }
    setLoading(false)
  }, [business_id, jwt_token])

  useEffect(() => {
    getData()
  }, [getData])

  return (
		<div
			style={{
			  height: 800,
			  width: '100%',
			  overflowX: 'auto',
			  display: 'flex',
			  flexDirection: 'column',
			  gap: 20
			}}
		>
			<DataGrid
				rows={data}
				columns={columns}
				pageSize={limit}
				rowsPerPageOptions={[limit]}
				components={{
				  Toolbar: GridToolbar,
				  NoRowsOverlay: () => (
						<CustomNoRowsOverlay plural="Offers" />
				  )
				}}
				style={{
				  overflowX: 'auto',
				  alignContent: 'left',
				  textAlign: 'left'
				}}
				onPageChange={(p) => {
				  setPage(p)
				}}
				rowCount={maxCount}
				loading={loading}
				page={page}
			/>
		</div>
  )
}

export default OffersView
