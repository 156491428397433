import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
  toolbar: {
    minHeight: 54
  },
  root: {
    backgroundColor: 'white',
    position: 'fixed',
    height: 'auto',
    padding: '5px 20px',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:600px)']: {
      padding: '5px 0'
    }
  },
  cta: {
    marginLeft: 'auto',
    // minWidth: 210,
    '& .host': {
      backgroundColor: `${theme.palette.secondary.main}`,
      textAlign: 'center',
      height: '45px',
      color: `${theme.palette.grey[50]}`,
      margin: '0 3px',
      border: `2px solid ${theme.palette.secondary.main}`,
      borderRadius: '10px',
      textTransform: 'none',
      '& .MuiButton-label': {
        display: 'inline',
        height: '14px',
        lineHeight: 'normal'
      },
      '&:hover': {
        backgroundColor: `${theme.palette.grey[50]}`,
        color: `${theme.palette.secondary.main}`
      }
    },
    '& .logout': {
      backgroundColor: '#FFFFFF',
      color: `${theme.palette.primary.main}`,
      textAlign: 'center',
      height: '45px',
      margin: '0 3px',
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '10px',
      textTransform: 'none',
      '& .MuiButton-label': {
        display: 'inline',
        height: '14px',
        lineHeight: 'normal'
      },
      '&:hover': {
        backgroundColor: `${theme.palette.primary.main}`,
        color: '#FFFFFF'
      }
    }
  }
}))
