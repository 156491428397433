import React, { useContext } from 'react'
import {
  AppBar,
  Button,
  Toolbar,
  useMediaQuery,
  useTheme
} from '@mui/material'
import styles from './HeaderStyles'
import { Link, useHistory } from 'react-router-dom'
import { UserContext } from '../../../Contexts/UserContext'
import Nearcast from 'ui/Icons/Nearcast'
import NearcastSmall from 'ui/Icons/NearcastSmall'

function Header (props) {
  const classes = styles()
  const history = useHistory()
  const small = useMediaQuery('(max-width: 600px)')
  const { logout } = useContext(UserContext)
  const { palette } = useTheme()

  return (
		<AppBar className={classes.root} elevation={1}>
			<Toolbar className={classes.toolbar}>
				<Link to={'/'}>
					{small
					  ? (
						<NearcastSmall
							height={35}
							style={{ display: 'block' }}
							htmlColor={palette.primary.main}
						/>
					    )
					  : (
						<Nearcast
							height={35}
							style={{ display: 'block' }}
							htmlColor={palette.primary.main}
						/>
					    )}{' '}
				</Link>
				<div className={classes.cta}>
					<Button
						onClick={() => {
						  logout()
						  history.push('/login')
						}}
						className="logout"
						variant="text"
					>
						Logout
					</Button>
				</div>
			</Toolbar>
		</AppBar>
  )
}

export default Header
