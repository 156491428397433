import React from 'react'
import {
  Modal,
  Card,
  CardProps,
  IconButton,
  Button,
  Typography,
  styled
} from '@mui/material'
import { ArrowBack, CloseRounded } from '@mui/icons-material'

const StyledCard = styled(Card)<CardProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  width: 800,
  padding: 20,
  outline: 'none',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    width: '60vw',
    maxHeight: '90vh'
  },
  [theme.breakpoints.down('sm')]: {
    width: '92vw',
    maxHeight: '90vh'
  }
}))

export interface WideModalButtonProps {
  title: string;
  onPress: () => void;
  color: 'primary' | 'secondary' | 'ghost';
  variant?: 'contained' | 'outlined' | 'text';
}

export interface WideModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  isTitleBack?: boolean;
  onPressTitleBack?: () => void;
  buttons?: WideModalButtonProps[];
  buttonsAlign?: 'flex-start' | 'center' | 'flex-end' | 'space-around' | 'space-between' | 'space-evenly';
  style?: any;
  children: any;
}

const WideModal: React.FC<WideModalProps> = ({
  isOpen,
  onClose,
  title,
  isTitleBack,
  onPressTitleBack,
  buttons,
  buttonsAlign = 'flex-end',
  children
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <StyledCard>
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            marginBottom: 12
          }}
        >
          {isTitleBack && (
            <IconButton
              style={{
                width: '16px',
                height: '16px',
                marginRight: '8px'
              }}
              onClick={onPressTitleBack}
              size="large"
            >
              <ArrowBack />
            </IconButton>
          )}
          <Typography variant="h3" color="primary">
            {title}
          </Typography>
          <IconButton
            style={{
              backgroundColor: '#bdbdbd',
              width: '14px',
              height: '14px',
              marginLeft: 'auto'
            }}
            onClick={onClose}
            size="large"
          >
            <CloseRounded />
          </IconButton>
        </div>
        <div
          style={{
            width: '100%',
            maxHeight: '70vh',
            overflowY: 'auto',
            paddingRight: 20
          }}
        >
          {children}
        </div>
        {buttons && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: buttonsAlign,
              alignItems: 'center',
              marginTop: 12
            }}
          >
            {buttons.map((button, index) => {
              return (
                <Button
                  variant={button.variant ? button.variant : 'contained'}
                  color={button.color}
                  onClick={button.onPress}
                  style={{
                    marginRight: 8,
                    marginLeft: 8
                  }}
                  disableElevation
                  key={index}
                >
                  {button.title}
                </Button>
              )
            })}
          </div>
        )}
      </StyledCard>
    </Modal>
  )
}

export default WideModal
