import axios from 'axios'

/**
 * @function signupEmail funcName's description
 * @param {string} email - what the param does
 * @param {string} name - user's current neighborhood id
 * @param {string} password - user's jwt token
 * @returns {Promise} Promise object represents
 */
export const signupEmail = (email, name, password) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/signup/email`, {
      email,
      name,
      password
    })
      .then((res) => {
        if (res.status === 209) {
          resolve(false)
        } else {
          resolve(true)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}
