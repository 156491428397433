import axios from 'axios'

export const adminOneTimeLogin = (id, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/admin/api/one_time_login`, {
      params: {
        id: id
      },
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => reject(err))
  })
}
