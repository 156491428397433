import axios from 'axios'

/**
 * @function authorAddPost Used to add a music file to the database
 * @param {string} jwt - the JWT token of the user (accessed through UserContext)
 * @param {string} formData - the form data object that is returned by the create music modal
 * @returns {Promise} Promise resolves to true if request is successful or else rejects with error
 */

export const musicAddPost = (jwt, formData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_ADMIN_URL}/admin/api/music/add`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }
      )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  })
}
