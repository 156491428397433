import * as React from 'react'
import {
  Typography,
  Stack,
  IconButton,
  Card,
  CardContent,
  CardMedia,
  Tooltip
} from '@mui/material'
import { PriorityHigh } from '@mui/icons-material'
import dayjs from 'dayjs'

// importing component
import LineChart from '../../../Charts/LineChart/LineChart'

// helper functions
const dataFormatter = (time, value, startTime, endTime) => {
  const mocked = []
  let tmp = startTime
  while (!dayjs(tmp).isAfter(endTime)) {
    mocked.push(tmp)
    tmp = dayjs(tmp).add(1, 'day').toISOString()
  }
  if (time.length === 0) {
    return mocked.map((mockedDate) => ({ x: dayjs(mockedDate).format('DD/MM/YYYY'), y: 0 }))
  } else {
    return mocked.map((m) => {
      const mockedDate = dayjs(m).format('DD/MM/YYYY')
      const timeData = time.filter((time) => {
        const timeDate = dayjs(time).format('DD/MM/YYYY')
        return timeDate === mockedDate
      })
      let tmp = 0
      timeData.forEach((e) => {
        const index = time.findIndex((v) => v === e)
        tmp = tmp + Number(value[index])
      })
      return {
        x: mockedDate,
        y: tmp
      }
    })
  }
}

export interface InsightGraphCardProps {
  title: string;
  helper: string;
  startTime: string;
  endTime: string;
  dataRequest: () => Promise<any>;
  style?: any;
}

const InsightGraphCard: React.FC<InsightGraphCardProps> = ({
  title,
  helper,
  startTime,
  endTime,
  dataRequest,
  style = {}
}) => {
  const [data, setData] = React.useState(dataFormatter([], [], startTime, endTime))
  const [loading, setLoading] = React.useState(true)

  const getBusinessAnalyticsData = () => {
    dataRequest()
      .then((data) => {
        if (data.time) {
          setData(dataFormatter(data.time, data.value, startTime, endTime))
          setLoading(false)
        }
      })
      .catch((err) => console.log(err.message))
  }

  React.useEffect(() => {
    if (dataRequest && loading) {
      getBusinessAnalyticsData()
    }
  }, [dataRequest, startTime, endTime])

  const getTotalCount = React.useCallback(() => {
    let tmp = 0
    data.forEach((d) => {
      tmp = tmp + d.y
    })
    return tmp
  }, [data])

  return (
    <Card
      elevation={0}
      sx={{
        width: {
          xs: '100%',
          sm: '280px'
        },
        marginRight: {
          xs: 'auto',
          sm: '8px'
        },
        marginBottom: '8px'
      }}
      style={{
        borderRadius: 10,
        border: '1px solid #EBEBEB',
        ...style
      }}
    >
      <CardContent>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">
            {title}
          </Typography>
          <Tooltip title={helper}>
            <IconButton
              style={{
                backgroundColor: 'grey',
                width: 16,
                height: 16,
                color: 'white'
              }}>
              <PriorityHigh style={{ width: 16 }} />
            </IconButton>
          </Tooltip>
        </Stack>
        <Stack direction="column">
          <Typography variant="h5">
            {getTotalCount()}
          </Typography>
        </Stack>
      </CardContent>
      <CardMedia style={{ paddingBottom: 4 }}>
        <LineChart data={data}/>
      </CardMedia>
    </Card>
  )
}

export default InsightGraphCard
