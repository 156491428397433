import axios from 'axios'

/**
 * @function adminLoginPost Used to login to the admin portal
 * @param {string} token - the id_token returned by Google OAuth
 * @returns {Promise} Promise object represents
 */

export const adminLoginPost = (token) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/admin/api/login`, {
        id_token: token
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  })
}
