import React from 'react'
import {
  Modal,
  Card,
  CardProps,
  IconButton,
  Button,
  Typography,
  styled
} from '@mui/material'
import { ArrowBack, CloseRounded } from '@mui/icons-material'

const StyledCard = styled(Card)<CardProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  width: 400,
  padding: 20,
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    width: '90vw'
  },
  outline: 'none'
}))

export interface StandardModalButtonProps {
  title: string;
  onPress: () => void;
  color: 'primary' | 'secondary' | 'ghost';
}

export interface StandardModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  isTitleBack?: boolean;
  onPressTitleBack?: () => void;
  buttons?: StandardModalButtonProps[];
  style?: any;
  children: any;
}

const StandardModal: React.FC<StandardModalProps> = ({
  isOpen,
  onClose,
  title,
  isTitleBack,
  onPressTitleBack,
  buttons,
  children
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <StyledCard>
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            marginBottom: 12
          }}
        >
          {isTitleBack && (
            <IconButton
              style={{
                width: '16px',
                height: '16px',
                marginRight: '8px'
              }}
              onClick={onPressTitleBack}
              size="large"
            >
              <ArrowBack />
            </IconButton>
          )}
          <Typography variant="h4" color="primary">
            {title}
          </Typography>
          <IconButton
            style={{
              width: '14px',
              height: '14px',
              marginLeft: 'auto'
            }}
            onClick={onClose}
            size="large"
          >
            <CloseRounded />
          </IconButton>
        </div>
        <div
          style={{
            width: '100%',
            maxHeight: '70vh',
            overflowY: 'auto',
            paddingRight: 20
          }}
        >
          {children}
        </div>
        {buttons && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 12
            }}
          >
            {buttons.map((button, index) => {
              return (
                <Button
                  variant="contained"
                  color={button.color}
                  onClick={button.onPress}
                  style={{
                    marginRight: 8,
                    marginLeft: 8
                  }}
                  disableElevation
                  key={index}
                >
                  {button.title}
                </Button>
              )
            })}
          </div>
        )}
      </StyledCard>
    </Modal>
  )
}

export default StandardModal
