import * as React from 'react'
import {
  CircularProgress,
  Grid,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
  FormHelperText,
  MenuItem,
  MenuList,
  Button,
  useTheme
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Check, Close } from '@mui/icons-material'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'
import { useSnackbar } from 'notistack'
import { UserContext } from '../../../Contexts/UserContext'
import AuthPopup from './AuthPopup'
import { businessCustomUrlCheckGet } from 'api/business/custom'

function CreateBusiness(props) {
  const classnames = styles()
  const theme = useTheme()
  const { jwt_token } = React.useContext(UserContext)
  const { enqueueSnackbar } = useSnackbar()
  // component states
  const [name, setName] = React.useState('')
  const [address, setAddress] = React.useState('')
  const [alias, setAlias] = React.useState('')
  const [city, setCity] = React.useState('')
  const [state, setState] = React.useState('')
  const [zip, setZip] = React.useState('')
  const [country, setCountry] = React.useState('')
  const [disable, setDisable] = React.useState({
    city: false,
    state: false,
    country: false,
    zip: false
  })
  const [availLoad, setAvailLoad] = React.useState(false)
  const [avail, setAvail] = React.useState(false)
  const [aliasErrorText, setAliasErrorText] = React.useState('')
  const [data, setData] = React.useState({})
  const [dialog, setDialog] = React.useState(false)
  const [latlon, setLatlon] = React.useState({})

  const selectPlace = () => {
    setData({
      is_business: true,
      business: {
        business_name: name,
        address: {
          address_line: address,
          city: city,
          state: state,
          country: country,
          zip: Number(zip)
        },
        custom_url: `https://nearcast.biz/${alias}`
      },
      address: {
        address: address,
        city: city,
        state: state,
        country: country,
        zip: Number(zip),
        lat: latlon.lat,
        lon: latlon.lng
      },
      lat: latlon.lat,
      lon: latlon.lng
    })
    setDialog(true)
  }

  const selectPlaceAPI = (add, pId, sugg) => {
    clearAddress()
    setAddress(add)
    geocodeByAddress(add)
      .then((results) => {
        const pre = {}
        for (let i = 0; i < results[0].address_components.length; i++) {
          if (results[0].address_components[i].types.includes('country')) {
            setCountry(results[0].address_components[i].long_name)
            pre.country = results[0].address_components[i].short_name

            setDisable((prev) => ({
              ...prev,
              country: true
            }))
          }
          if (
            results[0].address_components[i].types.includes(
              'administrative_area_level_1'
            )
          ) {
            setState(results[0].address_components[i].long_name)
            pre.state = results[0].address_components[i].short_name
            setDisable((prev) => ({
              ...prev,
              state: true
            }))
          }
          if (results[0].address_components[i].types.includes('locality')) {
            setCity(results[0].address_components[i].long_name)
            pre.city = results[0].address_components[i].short_name
            setDisable((prev) => ({
              ...prev,
              city: true
            }))
          }
          if (results[0].address_components[i].types.includes('sublocality')) {
            setAddress(results[0].address_components[i].long_name)
          }
          if (results[0].address_components[i].types.includes('postal_code')) {
            if (!isNaN(results[0].address_components[i].long_name)) {
              setZip(Number(results[0].address_components[i].long_name))
              setDisable((prev) => ({
                ...prev,
                zip: true
              }))
            }
          }
        }
        // Breadcrumbs, if needed in future
        // setPreAlias(
        //   `${pre.country}/${pre.state}/${pre.city}/`
        //     .toLowerCase()
        //     .replace(/\s|\,/g, "")
        // );
        return getLatLng(results[0])
      })
      .then((latLng) => {
        setLatlon(latLng)
      })
      .catch((error) => console.error('Error', error))
  }

  const validate = () => {
    if (
      name !== '' &&
      address !== '' &&
      city !== '' &&
      state !== '' &&
      zip !== '' &&
      country !== '' &&
      avail
    ) {
      selectPlace()
    } else {
      enqueueSnackbar('All field are compulsory', { variant: 'error' })
    }
  }

  const clearAddress = () => {
    setCity('')
    setState('')
    setZip('')
    setCountry('')

    setDisable({
      city: false,
      state: false,
      country: false,
      zip: false
    })
  }

  React.useEffect(() => {
    const checkURL = async () => {
      const regex =
        /^https:\/\/nearcast\.biz\/[a-z0-9]+(?:-[a-z0-9]+)*$/g

      if (alias === '' || !regex.test(`https://nearcast.biz/${alias}`)) {
        setAvailLoad(false)
        // console.log(alias, !regex.test(`https://nearcast.biz/${alias}`));
        setAvail(false)
        setAliasErrorText(
          'Only lowercase alphabets, numbers and hypens allowed.'
        )
        return
      }

      businessCustomUrlCheckGet(`https://nearcast.biz/${alias}`)
        .then((res) => {
          setAvailLoad(false)

          setAvail(!res.body.is_claimed_already)

          if (res.body.is_claimed_already) {
            setAliasErrorText('URL not available')
          } else {
            setAliasErrorText('')
          }
        })
        .catch((err) => {
          setAvailLoad(false)
          setAvail(false)
        })
    }
    const delayDebounceFn = setTimeout(() => {
      checkURL()
    }, 500)
    setAvailLoad(true)
    return () => clearTimeout(delayDebounceFn)
  }, [alias])

  React.useEffect(() => {
    setAlias(name.toLowerCase().trim().replace(/\s|,|'|\//g, '-'))
  }, [name])

  return (
    <Grid
      container
      className={classnames.form}
      spacing={2}
    >
      <Typography variant="h4" style={{ color: '#0C3A2D' }}>
        Create a Business
      </Typography>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            label="Business Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
            inputProps={{
              placeholder: 'Enter Business Name'
            }}
            style={{ marginBottom: 12 }}
          />
        </FormControl>
        <PlacesAutocomplete
          value={address}
          onChange={(e) => setAddress(e)}
          onSelect={selectPlaceAPI}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => (
            <>
              <FormControl fullWidth>
                <TextField
                  id="street"
                  variant="outlined"
                  label="Street Address"
                  value={address}
                  inputProps={{
                    ...getInputProps({
                      placeholder: 'Search Places ...',
                      className: 'location-search-input',
                      role: 'presentation'
                    })
                  }}
                />
              </FormControl>
              {loading || suggestions.length > 0 ? (
                <div>
                  <MenuList
                    style={{
                      position: 'absolute',
                      zIndex: 1000002,
                      maxHeight: 100,
                      width: '94%',
                      background: 'white',
                      border: '1px solid #d3d3d3',
                      borderRadius: 3,
                      overflow: 'auto'
                    }}
                  >
                    {loading && <MenuItem value="">Loading...</MenuItem>}
                    {suggestions.map((suggestion) => {
                      // inline style for demonstration purpose
                      const style = {
                        backgroundColor: suggestion.active
                          ? '#efefef'
                          : '#ffffff',
                        cursor: 'pointer',
                        padding: '2px 12px',
                        fontSize: '15px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: 'list-item'
                      }
                      return (
                        <MenuItem key={suggestion.placeId}
                          {...getSuggestionItemProps(suggestion, {
                            style
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </MenuItem>
                      )
                    })}
                  </MenuList>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </PlacesAutocomplete>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <TextField
            label="City"
            variant="outlined"
            value={city}
            inputProps={{
              placeholder: 'Enter City'
            }}
            onChange={(e) => setCity(e.target.value)}
            disabled={disable.city}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            label="State"
            value={state}
            inputProps={{
              placeholder: 'Enter State'
            }}
            onChange={(e) => setState(e.target.value)}
            disabled={disable.state}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <TextField
            label="Zipcode"
            variant="outlined"
            value={zip}
            inputProps={{
              placeholder: 'Enter Zipcode'
            }}
            onChange={(e) => setZip(e.target.value)}
            disabled={disable.zip}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            label="Country"
            value={country}
            inputProps={{
              placeholder: 'Enter Address'
            }}
            onChange={(e) => setCountry(e.target.value)}
            disabled={disable.country}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            label="Nearcast URL"
            value={alias}
            onChange={(e) => {
              setAlias(e.target.value.toLowerCase().trim().replace(/\s|,|'|\//g, '-'))
            }}
            InputProps={{
              placeholder: 'Your Business Name',
              startAdornment: (
                <InputAdornment> nearcast.biz/</InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {availLoad
                    ? (
                      <CircularProgress size={16} />
                    )
                    : avail
                      ? (
                        <Check htmlColor="green" />
                      )
                      : (
                        <Close htmlColor="red" />
                      )}
                </InputAdornment>
              )
            }}
          />
          {avail
            ? (
              <></>
            )
            : (
              <FormHelperText>{aliasErrorText}</FormHelperText>
            )}
        </FormControl>
      </Grid>
      <div
        style={{
          width: '100%',
          fontSize: '1.6rem',
          textAlign: 'right',
          color: `${theme.palette.secondary.light}`,
          padding: '10px 20px 20px 20px'
        }}
      >
        <Button
          onClick={validate}
          variant="contained"
          color="primary"
          disableElevation
        >
          Create Page
        </Button>
      </div>
      <AuthPopup open={dialog} setOpen={setDialog} business={data} />
    </Grid>
  )
}

const styles = makeStyles(({ palette }) => ({
  form: {
    margin: 0,
    width: 400,
    '@media (max-width: 600px)': {
      margin: 0,
      width: 400
    }
  }
}))

export default CreateBusiness
