import React, { useContext, useMemo } from 'react'
import Sidebar from '../../Components/Dash/Sidebar/Sidebar'
import Header from '../../Components/Dash/Header/Header'
import { Redirect, Switch, Route } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import { UserContext } from '../../Contexts/UserContext'
import DashHome from '../../Components/Dash/DashHome/DashHome'
import CreateBusiness from '../../Components/Dash/CreateBusiness/CreateBusiness'

import GetAll from '../../Components/Dash/GetAll'
import { tables } from '../../config/tables'
import GetOne from '../../Components/Dash/GetOne'
import OffersView from '../../Components/Dash/AllBusiness/OffersView'

// importing pages
import AllBusinessReviews from '../AllBusinessReviews/AllBusinessReviews'
import AllReports from '../AllReports/AllReports'
import BusinessCampaign from '../BusinessCampaign/BusinessCampaign'

// importing context
import { GlobalFuncContext } from '../../Contexts/GlobalFuncHOC'

const style = makeStyles(({ palette }) => {
  return {
    root: {
      margin: '64px 260px auto',
      // eslint-disable-next-line no-useless-computed-key
      ['@media (max-width: 1100px)']: {
        margin: '127px 10px auto'
      }
    },
    fill: {
      display: 'flex',
      justifyContent: 'center',
      // eslint-disable-next-line no-useless-computed-key
      ['@media (max-width: 1100px)']: {
        height: 'calc(100vh - 117px)'
      }
    }
  }
})

function Dashboard () {
  const classes = style()
  const { jwt_token } = useContext(UserContext)
  const {
    openRespondToFeature,
    openAddMusicAuthor,
    openAddMusic
  } = useContext(GlobalFuncContext)

  const renderTableContent = useMemo(() => {
    return tables(
      openRespondToFeature,
      openAddMusicAuthor,
      openAddMusic
    )
  }, [
    tables,
    openRespondToFeature,
    openAddMusicAuthor,
    openAddMusic
  ])

  if (!jwt_token) {
    return <Redirect to="/login" />
  }

  return (
    <div>
      <Header />
      <Sidebar />
      <div
        className={classes.root}
        style={{
          marginTop: '120px',
          paddingBottom: '100px',
          width: '80%',
          overflowX: 'auto'
        }}
      >
        <Switch>
          <Route
            exact
            path={['/home', '/', '/dash', 'dashboard']}
            component={DashHome}
          />
          <Route
            path="/createBusiness"
            component={CreateBusiness}
          />
          <>
            {renderTableContent.map(({ path, ...props }) => (
              <Switch>
                <Route key={path} path={path + '/:id'}>
                  <GetOne {...props} />
                </Route>
                <Route key={path} path={path}>
                  <GetAll {...props} />
                </Route>
              </Switch>
            ))}
            <Route path="/offers/:business_id"><OffersView /></Route>
            <Route path="/allBusinessReviews" component={AllBusinessReviews} />
            <Route path="/allReports" component={AllReports} />
            <Route path="/business_campaign" component={BusinessCampaign} />
          </>
        </Switch>
      </div>
    </div>
  )
}

export default Dashboard
