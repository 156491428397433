import CreateMusic from '../Components/Dash/modals/CreateMusic'
import { Button, Typography, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { useModal } from '../Contexts/ModalContext.jsx'
import CreateAuthor from '../Components/Dash/modals/CreateAuthor'
import EditURLBtn from '../Components/Dash/AllBusiness/EditURLBtn'
import React from 'react'

// importing helpers
import { getUserDomainURL } from 'helpers/url'

export const tables = (
  openRespondToFeature = (fId = '') => {},
  openAddMusicAuthor = (nId = '') => {},
  openAddMusic = (nId = '') => {}
) => {
  return (
    [
      {
        label: { singular: 'Nearcast', plural: 'Nearcasts' },
        path: '/nearcasts',
        endpoints: {
          getAll: 'nearcast',
          blockToggle: (id, status) => `block/nearcast?post=${id}&report=${status}`,
          getOne: (id) => `fetch/nearcast?post=${id}`
        },
        fields: [
          {
            field: 'sno',
            headerName: 'S.No',
            align: 'left'
          },
          {
            field: 'username',
            headerName: 'Name',
            minWidth: 200,
            renderCell: ({ row }) =>
              (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={getUserDomainURL(`/u/${row.personal_account_id}`)}
                >
                  {row.username}
                </a>
              )
          },
          {
            field: 'city',
            headerName: 'City',
            minWidth: 150,
            align: 'left'
          },
          {
            field: 'neighbourhood',
            headerName: 'Neighborhood',
            minWidth: 200,
            align: 'left'
          },
          {
            field: 'caption',
            headerName: 'Caption',
            minWidth: 300,
            align: 'left'
          },
          {
            field: 'id',
            headerName: 'Watch Nearcast',
            minWidth: 200,
            align: 'left',
            renderCell: (postId) => (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getUserDomainURL(`/p/${postId.id}`)}
              >
                Watch Short Video
              </a>
            )
          },
          {
            field: 'created_at',
            headerName: 'Created At',
            minWidth: 120,
            valueGetter: (params) => new Date(params.row.created_at),
            type: 'date'
          },
          {
            field: 'review_id',
            headerName: 'Review',
            type: 'boolean',
            align: 'center'
          },
          {
            field: 'distance_constant',
            headerName: 'Distance',
            type: 'number',
            minWidth: 130,
            align: 'center'
          },
          {
            field: 'is_blocked',
            headerName: 'Blocked Status',
            minWidth: 130,
            align: 'left'
          }
        ]
      },
      {
        label: { singular: 'Gathering', plural: 'Gatherings' },
        path: '/gatherings',
        endpoints: {
          getAll: 'gatherings',
          blockToggle: (id, status) => `block/gathering?gathering=${id}&report=${status}`
        },
        fields: [
          {
            field: 'sno',
            headerName: 'S.No',
            minWidth: 90,
            align: 'left'
          },
          {
            field: 'name',
            headerName: 'Name',
            minWidth: 170,
            align: 'left'
          },
          {
            field: 'city',
            headerName: 'City',
            minWidth: 150,
            align: 'left'
          },
          {
            field: 'neighbourhood',
            headerName: 'Neighborhood',
            minWidth: 200,
            align: 'left'
          },
          {
            field: 'description',
            headerName: 'Description',
            minWidth: 300,
            align: 'left'
          },
          {
            field: 'distance',
            headerName: 'Distance',
            type: 'number',
            minWidth: 130,
            align: 'center'
          },
          {
            field: 'created_at',
            headerName: 'Created At',
            minWidth: 170,
            align: 'left',
            valueGetter: (params) => new Date(params.row.created_at),
            type: 'date'
          },
          {
            field: 'ends_at',
            headerName: 'Ends At',
            minWidth: 170,
            align: 'left',
            valueGetter: (params) => new Date(params.row.ends_at),
            type: 'date'
          },
          {
            field: 'is_recorded',
            headerName: 'Recording Status',
            minWidth: 130,
            align: 'left'
          },
          {
            field: 'is_blocked',
            headerName: 'Block Status',
            minWidth: 130,
            align: 'left'
          },
          {
            field: 'is_private',
            headerName: 'Visibility',
            minWidth: 130,
            align: 'left'
          }
        ]
      },
      {
        label: { singular: 'Group', plural: 'Groups' },
        path: '/groups',
        endpoints: {
          getAll: 'groups',
          blockToggle: (id, status) => `block/group?group=${id}&report=${status}`
        },
        fields: [
          {
            field: 'sno',
            headerName: 'S.No',
            minWidth: 90,
            align: 'left'
          },
          {
            field: 'group_name',
            headerName: 'Name',
            minWidth: 170,
            align: 'left'
          },
          {
            field: 'group_description',
            headerName: 'Description',
            minWidth: 300,
            align: 'left'
          },
          {
            field: 'city',
            headerName: 'City',
            minWidth: 150,
            align: 'left'
          },
          {
            field: 'neighbourhood',
            headerName: 'Neighborhood',
            minWidth: 200,
            align: 'left'
          },
          {
            field: 'distance',
            headerName: 'Distance',
            type: 'number',
            minWidth: 130,
            align: 'center'
          },
          {
            field: 'created_at',
            headerName: 'Created At',
            minWidth: 170,
            align: 'left',
            valueGetter: (params) => new Date(params.row.created_at),
            type: 'date'
          },
          {
            field: 'is_blocked',
            headerName: 'Block Status',
            minWidth: 130,
            align: 'left'
          },
          {
            field: 'is_private',
            headerName: 'Visibility',
            minWidth: 130,
            align: 'left'
          }
        ]
      },
      {
        label: { singular: 'User', plural: 'Users' },
        path: '/users',
        endpoints: {
          getAll: 'users',
          blockToggle: (id, status) => `block/account?id=${id}&report=${status}`,
          getOne: (id) => `fetch/user?user=${id}`
        },
        fields: [
          {
            field: 'sno',
            headerName: 'S.No',
            minWidth: 90,
            align: 'left'
          },
          {
            field: 'username',
            headerName: 'Username',
            minWidth: 150,
            align: 'left'
          },
          {
            field: 'first_name',
            headerName: 'First Name',
            minWidth: 170,
            align: 'left'
          },
          {
            field: 'last_name',
            headerName: 'Last Name',
            minWidth: 170,
            align: 'left'
          },
          {
            field: 'email',
            headerName: 'Email',
            minWidth: 170,
            align: 'left'
          },
          {
            field: 'phone',
            headerName: 'Phone',
            minWidth: 170,
            align: 'left'
          },
          {
            field: 'created_at',
            headerName: 'Created At',
            minWidth: 170,
            align: 'left',
            valueGetter: (params) => new Date(params.row.created_at),
            type: 'date'
          },
          {
            field: 'has_agreed',
            headerName: 'Community Agreement',
            minWidth: 130,
            align: 'left'
          },
          {
            field: 'is_email_verified',
            headerName: 'Email Verification',
            minWidth: 130,
            align: 'left'
          },
          {
            field: 'is_blocked',
            headerName: 'Status',
            minWidth: 130,
            align: 'left'
          }
        ]
      },
      {
        label: { singular: 'Neighbourhood', plural: 'Neighbourhoods' },
        path: '/neighbourhoods',
        endpoints: {
          getAll: 'neighbourhoods'
        },
        fields: [
          {
            field: 'sno',
            headerName: 'S.No',
            minWidth: 90,
            align: 'left'
          },
          {
            field: 'id',
            headerName: 'Music',
            minWidth: 120,
            renderCell: ({ row, id }) => {
              const { openModal } = useModal()
              return (
                <Button
                  onClick={() =>
                    // openModal(() => (
                    //   <CreateMusic defaults={{ neighbourhood_id: id }} />
                    // ))
                    openAddMusic(id)
                  }
                  variant="outlined"
                >
                  Add Music
                </Button>
              )
            }
          },
          {
            field: 'neighbourhood',
            headerName: 'Neighbourhood',
            minWidth: 200,
            align: 'left'
          },
          {
            field: 'city',
            headerName: 'City',
            minWidth: 150,
            align: 'left'
          },
          {
            field: 'state',
            headerName: 'State',
            minWidth: 150,
            align: 'left'
          },
          {
            field: 'country',
            headerName: 'Country',
            minWidth: 150,
            align: 'left'
          },
          {
            field: 'currency_name',
            headerName: 'Currency',
            minWidth: 150,
            align: 'left'
          }
        ]
      },
      {
        label: { singular: 'Music', plural: 'Music' },
        path: '/musics',
        endpoints: {
          getAll: 'musics'
        },
        openCreateModal: () => openAddMusic(),
        fields: [
          {
            field: 'sno',
            headerName: 'S.No',
            minWidth: 90,
            align: 'left'
          },
          {
            field: 'author_name',
            headerName: 'Author',
            minWidth: 200,
            align: 'left'
          },
          {
            field: 'song_name',
            headerName: 'Song',
            minWidth: 200,
            align: 'left'
          },
          {
            field: 'track',
            headerName: 'Track',
            minWidth: 200,
            align: 'left',
            renderCell: (entry) => (
              <audio controls>
                <source src={entry.row.track} />
              </audio>
            )
          },
          {
            field: 'cover',
            headerName: 'Cover',
            minWidth: 200,
            align: 'left',
            renderCell: (entry) => (
              <img style={{ maxHeight: '100%' }} src={entry.row.cover} />
            )
          },
          {
            field: 'city',
            headerName: 'City',
            minWidth: 150,
            align: 'left'
          },
          {
            field: 'state',
            headerName: 'State',
            minWidth: 150,
            align: 'left'
          },
          {
            field: 'neighbourhood',
            headerName: 'Neighbourhood',
            minWidth: 200,
            align: 'left'
          }
        ]
      },
      {
        label: { singular: 'Author', plural: 'Authors' },
        path: '/authors',
        endpoints: {
          getAll: 'music_authors'
        },
        openCreateModal: () => openAddMusicAuthor(),
        fields: [
          {
            field: 'sno',
            headerName: 'S.No',
            minWidth: 90,
            align: 'left'
          },
          {
            field: 'name',
            headerName: 'Name',
            minWidth: 150,
            align: 'left'
          },
          {
            field: 'neighbourhood',
            headerName: 'Neighbourhood',
            minWidth: 150,
            align: 'left'
          },
          {
            field: 'city',
            headerName: 'City',
            minWidth: 150,
            align: 'left'
          },
          {
            field: 'state',
            headerName: 'State',
            minWidth: 150,
            align: 'left'
          },
          {
            field: 'picture',
            headerName: 'Picture',
            minWidth: 200,
            align: 'left',
            renderCell: (entry) => (
              <img style={{ maxHeight: '100%' }} src={entry.row.picture} />
            )
          }
        ]
      },
      {
        label: { singular: 'Business', plural: 'Businesses' },
        path: '/businesses',
        endpoints: {
          getAll: 'business',
          blockToggle: (id, status) => `block/business?id=${id}&report=${status}`,
          getOne: (id) => `fetch/nearcast?post=${id}`,
          isAdminBusinessLogin: true
        },
        fields: [{
          field: 'sno',
          headerName: 'S.No',
          minWidth: 90,
          align: 'left'
        },
        {
          field: 'name',
          headerName: 'Name',
          minWidth: 150,
          align: 'left'
        },
        {
          field: 'address_line',
          headerName: 'Address',
          width: 150,
          align: 'left'
        },
        {
          field: 'city',
          headerName: 'City',
          minWidth: 150,
          align: 'left'
        },
        {
          field: 'state',
          headerName: 'State',
          minWidth: 150,
          align: 'left'
        },
        {
          field: 'rating',
          headerName: 'Rating',
          align: 'left',
          renderCell: ({ row }) => {
            return Number(row.rating).toFixed(1)
          }
        },
        {
          field: 'no_of_reviewers',
          headerName: 'Reviews',
          align: 'left'
        },
        {
          field: 'hidden_nearcast_count',
          headerName: 'Hidden Reviews',
          minWidth: 150,
          align: 'left'
        },
        {
          field: 'custom_url',
          headerName: 'Custom URL',
          minWidth: 150,
          align: 'left'
        },
        {
          field: 'id',
          headerName: 'Edit Custom URL',
          minWidth: 200,
          align: 'left',
          renderCell: (entry) => (
            <EditURLBtn data={entry.row}/>
          )
        },
        {
          field: ' ',
          headerName: 'View Offers',
          minWidth: 150,
          align: 'left',
          renderCell: (entry) => (
            <Button component={Link} to={`/offers/${entry.row.id}`} variant="outlined" color="secondary">View Offers</Button>
          )
        }
        ]
      },
      {
        label: { singular: 'Feature', plural: 'Features' },
        path: '/feature',
        endpoints: {
          getAll: 'featurerequests'
        },
        fields: [
          {
            field: 'sno',
            headerName: 'S.No',
            minWidth: 90,
            align: 'left'
          },
          {
            field: 'title',
            headerName: 'Title',
            minWidth: 300,
            align: 'left'
          },
          {
            field: 'description',
            headerName: 'Description',
            minWidth: 500,
            align: 'left',
            overflowX: 'scroll',
            renderCell: (v) => {
              return (
                <Stack
                  direction="row"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  style={{
                    width: '100%',
                    overflowX: 'auto'
                  }}
                >
                  {v.row.description}
                </Stack>
              )
            }
          },
          {
            field: 'response',
            headerName: 'Response',
            minWidth: 150,
            align: 'left'
          },
          {
            field: 'status',
            headerName: 'Status',
            minWidth: 150,
            align: 'left',
            renderCell: (v) => {
              const mappedText = new Map([
                [0, 'New'],
                [1, 'UnderReview'],
                [2, 'Approved'],
                [3, 'Closed (Duplicate)'],
                [4, 'Closed (Out of scope)']
              ])
              const mappedColor = new Map([
                [0, 'primary'],
                [1, 'ghost'],
                [2, 'primary'],
                [3, 'secondary'],
                [4, 'secondary']
              ])
              return (
                <Typography color={mappedColor.get(v.row.status)}>
                  {mappedText.get(v.row.status)}
                </Typography>
              )
            }
          },
          {
            field: 'id',
            headerName: 'Action: Respond',
            minWidth: 150,
            align: 'left',
            renderCell: (v) => {
              return (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => openRespondToFeature(v.row.id)}
                >
                  Respond
                </Button>
              )
            }
          }
        ]
      }
    ]
  )
}

export const globalTranslate = {
  is_blocked: { true: 'Blocked', false: 'Active' },
  is_private: { true: 'Private', false: 'Public' },
  is_recorded: { true: 'Recorded', false: 'Not Recorded' },
  is_email_verified: { true: 'Verified', false: 'Not Verified' },
  created_at: (created_at) => new Date(created_at).toDateString(),
  ends_at: (ends_at) =>
    new Date(new Date(ends_at).toDateString()).toDateString()
}
