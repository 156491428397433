import axios from 'axios'

export const businessCustomUrlCheckGet = (url) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_BUSINESS_URL}/business/custom/url/check?url=${url}`
      )
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err)
      })
  })
}
