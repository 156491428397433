import * as React from 'react'

const ImagePicker: React.FC<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>> = (allProps) => {
  const { children, onChange, ...props } = allProps
  const inputRef = React.useRef(null)

  return (
    <div
      onClick={() => {
        if (children) {
          inputRef.current.click()
        }
      }}
    >
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        id="contained-file"
        type="file"
        accept="image/jpeg, image/jpg, image/png, image/webp"
        onClick={(e) => { e.target.value = null }} // onChange is not called if same file is selected, this makes sure that onChange is always called
        onChange={(e) => {
          if (e.target.files.length === 0) {
            return
          }
          onChange(e)
        }}
        {...props}
      />
      {children}
    </div>
  )
}

export default ImagePicker
