import { useEffect, useState } from 'react'
import { Button, FormControl, Grid } from '@mui/material'
import { MapContainer, Polygon } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import { AuthLayout } from './AuthPopup'
import { getLocation } from 'api/get/location'
import { getGeoJson } from 'api/get/geojson'

export default function MapDisplay ({ business, action }) {
  const [neighborhood, setNeigh] = useState('')
  const [id, setId] = useState()
  const [loading, setLoading] = useState(false)
  const [geoJSON, setGeoJSON] = useState([[[0, 0]]])
  const [key, setKey] = useState(0)
  const [center, setCenter] = useState([0, 0])

  const getBoundary = async (lat, lon) => {
    setLoading(true)
    getLocation(lon, lat)
      .then((res) => {
        setNeigh(res.data[0].neighbourhood)
        setId(res.data[0].obj_id)
      })
      .catch((err) => {
        setNeigh('Your Neighborhood')
        setCenter([lat, lon])
        setKey((prev) => prev + 1)
        setLoading(false)
      })
  }

  const getGeoData = async (id) => {
    getGeoJson(id)
      .then((res) => {
        let x = 0
        let y = 0
        const coor = [[[0, 0]]]
        for (let i = 0; i < res.data.coordinates[0][0].length; i++) {
          x = x + res.data.coordinates[0][0][i][0]
          y = y + res.data.coordinates[0][0][i][1]
          coor[0][0][i] = [
            res.data.coordinates[0][0][i][1],
            res.data.coordinates[0][0][i][0]
          ]
        }
        x = x / res.data.coordinates[0][0].length
        y = y / res.data.coordinates[0][0].length
        setGeoJSON(coor)
        setCenter([y, x])
        setKey((prev) => prev + 1)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getBoundary(business.lat, business.lon)
  }, [])

  useEffect(() => {
    getGeoData(id)
  }, [id])

  return (
		<AuthLayout title={`Broadcast to ${neighborhood}`}>
			<Grid item style={{ width: '100%', aspectRatio: '16/11' }}>
				<div
					style={{
					  width: '100%',
					  aspectRatio: '16/9',
					  margin: 'auto',
					  display: 'block'
					}}
				>
					<MapContainer
						style={{
						  height: '100%'
						}}
						center={center}
						zoom={13}
						key={key}
					>
						<ReactLeafletGoogleLayer apiKey="AIzaSyD65D3CME73uC7YqwJSRNkyZJ_eXLlSE1Y" />
						<Polygon positions={geoJSON} />
					</MapContainer>
				</div>
			</Grid>
			<Grid item style={{ marginTop: 'auto' }}>
				<FormControl style={{ textAlign: 'center' }}>
					<Button
						onClick={action}
						variant="contained"
						color="primary"
						disableElevation
						disabled={loading}
					>
						Continue
					</Button>
				</FormControl>
			</Grid>
		</AuthLayout>
  )
}
