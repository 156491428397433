import { Check, Close } from '@mui/icons-material'
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputBase,
  MenuItem,
  MenuList,
  Typography,
  Divider
} from '@mui/material'
import { businessCustomUrlCheckGet } from 'api/business/custom'
import { businessNeighbourhoodPost } from 'api/business/neighbourhood'
import { getGeoJson } from 'api/get/geojson'
import 'leaflet/dist/leaflet.css'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { MapContainer, Polygon } from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'
import WideModal from 'ui/Modals/WideModal'
import TextField from 'ui/TextInputs/PriceFieldsInput'
import { TextLabel } from 'ui/TextInputs/TextInput'

const CreateBusinessModal = ({
  isOpen,
  onClose,
  data,
  next,
  setOtpCountry,
  children
}) => {
  const [bname, setBname] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [country, setCountry] = useState('')
  const [alias, setAlias] = useState('')
  const [latlon, setLatlon] = useState({})
  const [avail, setAvail] = useState(false)
  const [availLoad, setAvailLoad] = useState(false)
  const [aliasErrorText, setAliasErrorText] = useState('')
  const [disable, setDisable] = useState({
    city: false,
    state: false,
    country: false,
    zip: false
  })

  const [neighborhood, setNeigh] = useState('')
  const [geoJSON, setGeoJSON] = useState([[[0, 0]]])
  const [key, setKey] = useState(0)
  const [center, setCenter] = useState([38.8338816, -104.8213634])
  const [zoom, setZoom] = useState(3)
  const { enqueueSnackbar } = useSnackbar()

  const getBoundary = async (lat, lon) => {
    setZoom(13)
    businessNeighbourhoodPost({ lon, lat })
      .then((res) => {
        setNeigh(res.data.body.neighbourhood)
        getGeoData(res.data.body.obj_id)
      })
      .catch((err) => {
        setNeigh('Your Neighborhood')
        setCenter([lat, lon])
        setKey((prev) => prev + 1)
      })
  }

  const getGeoData = async (id) => {
    getGeoJson(id)
      .then((res) => {
        let x = 0
        let y = 0
        const coor = [[[0, 0]]]
        for (let i = 0; i < res.data.coordinates[0][0].length; i++) {
          x = x + res.data.coordinates[0][0][i][0]
          y = y + res.data.coordinates[0][0][i][1]
          coor[0][0][i] = [
            res.data.coordinates[0][0][i][1],
            res.data.coordinates[0][0][i][0]
          ]
        }
        x = x / res.data.coordinates[0][0].length
        y = y / res.data.coordinates[0][0].length
        setGeoJSON(coor)
        setCenter([y, x])
        setKey((prev) => prev + 1)
      })
      .catch((err) => { })
  }

  const validate = () => {
    if (
      bname !== '' &&
      address !== '' &&
      city !== '' &&
      state !== '' &&
      zip !== '' &&
      country !== '' &&
      avail
    ) {
      next({
        is_business: true,
        business: {
          business_name: bname,
          address: {
            address_line: address,
            city: city,
            state: state,
            country: country,
            zip: Number(zip)
          },
          custom_url: `https://nearcast.biz/${alias}`
        },
        address: {
          address: address,
          city: city,
          state: state,
          country: country,
          zip: Number(zip),
          lat: latlon.lat,
          lon: latlon.lng
        },
        lat: latlon.lat,
        lon: latlon.lng
      })
    } else {
      enqueueSnackbar('All field are compulsory', { variant: 'error' })
    }
  }

  const clearAddress = () => {
    setCity('')
    setState('')
    setZip('')
    setCountry('')

    setDisable({
      city: false,
      state: false,
      country: false,
      zip: false
    })
  }

  const selectPlaceAPI = (add, pId, sugg) => {
    clearAddress()
    setAddress(add)
    if (sugg?.formattedSuggestion?.mainText) {
      setBname(sugg?.formattedSuggestion?.mainText)
    }
    geocodeByAddress(add)
      .then((results) => {
        const pre = {}
        for (let i = 0; i < results[0].address_components.length; i++) {
          if (results[0].address_components[i].types.includes('country')) {
            setCountry(results[0].address_components[i].long_name)
            pre.country = results[0].address_components[i].short_name
            setOtpCountry(results[0].address_components[i].short_name.toLowerCase())
            setDisable((prev) => ({
              ...prev,
              country: true
            }))
          }
          if (
            results[0].address_components[i].types.includes(
              'administrative_area_level_1'
            )
          ) {
            setState(results[0].address_components[i].long_name)
            pre.state = results[0].address_components[i].short_name
            setDisable((prev) => ({
              ...prev,
              state: true
            }))
          }
          if (results[0].address_components[i].types.includes('locality')) {
            setCity(results[0].address_components[i].long_name)
            pre.city = results[0].address_components[i].short_name
            setDisable((prev) => ({
              ...prev,
              city: true
            }))
          }
          // if (results[0].address_components[i].types.includes('sublocality')) {
          //   setAddress(results[0].address_components[i].long_name)
          // }
          if (results[0].address_components[i].types.includes('postal_code')) {
            if (!isNaN(results[0].address_components[i].long_name)) {
              setZip(Number(results[0].address_components[i].long_name))
              setDisable((prev) => ({
                ...prev,
                zip: true
              }))
            }
          }
        }
        return getLatLng(results[0])
      })
      .then((latLng) => {
        setLatlon(latLng)
      })
      .catch((error) => console.error('Error', error))
  }

  useEffect(() => {
    const checkURL = async () => {
      const regex =
        /^https:\/\/nearcast\.biz\/[a-z0-9]+(?:-[a-z0-9]+)*$/g

      if (alias.length === 0) {
        setAvailLoad(false)
        return
      }

      if (!regex.test(`https://nearcast.biz/${alias}`)) {
        setAvailLoad(false)
        setAvail(false)
        setAliasErrorText(
          'Only lowercase alphabets, numbers and hypens allowed.'
        )
        return
      }
      businessCustomUrlCheckGet(`https://nearcast.biz/${alias}`)
        .then((resData) => {
          setAvailLoad(false)
          setAvail(!resData.body.is_claimed_already)

          if (resData.body.is_claimed_already) {
            setAliasErrorText('URL not available')
          } else {
            setAliasErrorText('')
          }
        })
        .catch((err) => {
          setAvailLoad(false)
          setAvail(false)
        })
    }
    const delayDebounceFn = setTimeout(() => {
      checkURL()
    }, 500)
    setAvailLoad(true)
    return () => clearTimeout(delayDebounceFn)
  }, [alias])

  useEffect(() => {
    if ('lat' in latlon) getBoundary(latlon.lat, latlon.lng)
    // eslint-disable-next-line
  }, [latlon]);

  useEffect(() => {
    setAlias(bname.toLowerCase().trim().replace(/\s|,|'|\//g, '-'))
  }, [bname])

  useEffect(() => {
    if (data && data.business) {
      setBname(data.business.business_name)
      setAlias(
        data.business.custom_url.substring(
          data.business.custom_url.indexOf('z/') + 2
        )
      )
      selectPlaceAPI(data.address.address)
    }
  }, [])

  return (
    <WideModal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setBname('')
        setAddress('')
        setCity('')
        setState('')
        setZip('')
        setCountry('')
        setAlias('')
        setAvail(false)
        setAvailLoad(false)
        setNeigh('')
        setGeoJSON([[[0, 0]]])
      }}
      title={'Claim your business page '}
      buttons={[{ title: 'Create Page', color: 'primary', onPress: validate }]}
    >
      <Divider style={{ marginTop: 3 }} />
      <Grid container spacing={3} style={{ padding: 10 }}>
        <Grid container spacing={1} item xs={12} md={6}>
          <Grid item xs={12}>
            <PlacesAutocomplete
              value={address}
              onChange={(e) => {
                setAddress(e)
              }}
              onSelect={selectPlaceAPI}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <>
                  <FormControl fullWidth>
                    <TextField
                      id="street"
                      variant="outlined"
                      label="Street Address"
                      value={address}
                      inputProps={{
                        ...getInputProps({
                          placeholder: 'Search Places ...',
                          className: 'location-search-input',
                          role: 'presentation'
                        })
                      }}
                    />
                  </FormControl>
                  {loading || suggestions.length > 0 ? (
                    <div>
                      <MenuList
                        style={{
                          position: 'absolute',
                          zIndex: 1000002,
                          maxHeight: 100,
                          width: '94%',
                          background: 'white',
                          border: '1px solid #d3d3d3',
                          borderRadius: 3,
                          overflow: 'auto'
                        }}
                      >
                        {loading && <MenuItem value="">Loading...</MenuItem>}
                        {suggestions.map((suggestion) => {
                          // inline style for demonstration purpose
                          const style = {
                            backgroundColor: suggestion.active
                              ? '#efefef'
                              : '#ffffff',
                            cursor: 'pointer',
                            padding: '2px 12px',
                            fontSize: '15px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: 'list-item'
                          }
                          return (
                            <MenuItem key={suggestion.placeId}
                              {...getSuggestionItemProps(suggestion, {
                                style
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </MenuItem>
                          )
                        })}
                      </MenuList>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </PlacesAutocomplete>
          </Grid>
          <Grid item xs={12}>
            <Grid item style={{ width: '100%', aspectRatio: '16/11' }}>
              <div
                style={{
                  width: '100%',
                  aspectRatio: '16/9',
                  margin: 'auto',
                  display: 'block'
                }}
              >
                <MapContainer center={center} zoom={zoom} key={key}>
                  <ReactLeafletGoogleLayer apiKey="AIzaSyD65D3CME73uC7YqwJSRNkyZJ_eXLlSE1Y" />
                  <Polygon positions={geoJSON} />
                </MapContainer>
                {neighborhood.length > 0 && (
                  <Typography color={'primary'} variant="h4" mt={2}>
                    Broadcast to {neighborhood}
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} item xs={12} md={6}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                label="Business Name"
                value={bname}
                onChange={(e) => {
                  setBname(e.target.value)
                }}
                inputProps={{
                  placeholder: 'Enter Business Name'
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                label="City"
                variant="outlined"
                value={city}
                inputProps={{
                  placeholder: 'Enter City'
                }}
                onChange={(e) => setCity(e.target.value)}
                disabled={disable.city}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                label="State"
                value={state}
                inputProps={{
                  placeholder: 'Enter State'
                }}
                onChange={(e) => setState(e.target.value)}
                disabled={disable.state}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                label="Zipcode"
                variant="outlined"
                value={zip}
                inputProps={{
                  placeholder: 'Enter Zipcode'
                }}
                onChange={(e) => {
                  if (e.target.value.length > 11) {
                    return
                  }
                  setZip(e.target.value)
                }}
                disabled={disable.zip}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                label="Country"
                value={country}
                inputProps={{
                  placeholder: 'Enter Address'
                }}
                onChange={(e) => setCountry(e.target.value)}
                disabled={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextLabel
                style={{
                  marginLeft: 6,
                  fontSize: 14,
                  fontWeight: 600
                }}
              >
                Nearcast URL
              </TextLabel>
              <InputBase
                sx={[
                  {
                    marginTop: '8px',
                    borderRadius: '5px',
                    position: 'relative',
                    border: '1px solid transparent',
                    backgroundColor: '#8aa6de14',
                    fontSize: 16,
                    padding: '8px 16px',
                    fontFamily: 'Avenir Next'
                  },
                  {
                    '&:focus': {
                      border: '1px solid #8aa6de41'
                    }
                  }
                ]}
                value={alias}
                onChange={(e) => {
                  setAlias(e.target.value.toLowerCase().trim().replace(/\s|,|'|\//g, '-'))
                }}
                startAdornment={
                  <InputAdornment position="start">
                    {' '}
                    nearcast.biz/
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    {availLoad
                      ? (
                        <CircularProgress size={16} />
                        )
                      : avail
                        ? (
                          <Check htmlColor="green" />
                          )
                        : (
                          <Close htmlColor="red" />
                          )}
                  </InputAdornment>
                }
                inputProps={{
                  placeholder: 'Your Business Name'
                }}
              />
              {avail
                ? (
                  <></>
                  )
                : (
                  <FormHelperText error>{aliasErrorText}</FormHelperText>
                  )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: 3 }} />
      {children}
    </WideModal>
  )
}

export default CreateBusinessModal
