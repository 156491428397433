import * as React from 'react'
import Button from '@mui/material/Button'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { compressAccurately } from 'image-conversion'

import WideModal from '../../Modals/WideModal'

const CropImageModal = ({ open, onClose, onCropComplete, aspect, imageFile }) => {
  const [up, setUp] = React.useState()
  const imgRef = React.useRef(null)
  const previewCanvasRef = React.useRef(null)
  const [crop, setCrop] = React.useState({ unit: '%', width: 30, aspect: aspect })
  const [completedCrop, setCompletedCrop] = React.useState(null)
  const buttonStateInitial = 'Select'
  const [buttonState, setButtonState] = React.useState(buttonStateInitial) // Select -> Cropping -> Compressing

  const onLoad = React.useCallback((img) => {
    imgRef.current = img
  }, [])

  React.useEffect(() => {
    if (!imageFile) {
      return
    }
    setUp()
    const reader = new FileReader()
    reader.addEventListener('load', () => setUp(reader.result))
    reader.readAsDataURL(imageFile)
  }, [imageFile])

  const drawImageOnCanvas = () => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')
    const pixelRatio = window.devicePixelRatio

    canvas.width = crop.width * pixelRatio * scaleX
    canvas.height = crop.height * pixelRatio * scaleY

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    )
  }

  return (
      <WideModal
        isOpen={open}
        onClose={onClose}
    >
            <div
                className="App"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
            >
                <ReactCrop
                    src={up}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c) => setCompletedCrop(c)}
                    imageStyle={{
                      maxHeight: '60vh',
                      maxWidth: '100%'
                    }}
                    keepSelection
                />
                <div style={{ display: 'none' }}>
                    <canvas
                        ref={previewCanvasRef}
                        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                        style={{
                          width: Math.round(completedCrop?.width ?? 0),
                          height: Math.round(completedCrop?.height ?? 0)
                        }}
                    />
                </div>
                <Button
                    style={{
                      marginTop: 10
                    }}
                    variant="contained"
                    disableElevation
                    color="primary"
                    onClick={() => {
                      setButtonState('Cropping')
                      drawImageOnCanvas()
                      previewCanvasRef.current.toBlob(
                        async (blob) => {
                          const localFile = new File([blob], Date.now())
                          if (localFile.size > 1000000) { // js File object returns size in bytes: 10mb = 1000000 bytes
                            setButtonState('Compressing')
                            // compress image
                            const imgBlob = await compressAccurately(localFile, {
                              size: 400,
                              accuracy: 0.5
                            }) // 400kb is 0.4 mb
                            onCropComplete(imgBlob)
                          } else {
                            onCropComplete(blob)
                          }
                          setButtonState(buttonStateInitial) // revert button state to initial state
                          onClose()
                        },
                        imageFile.type,
                        1
                      )
                    }}
                    disabled={buttonState !== buttonStateInitial}
                    data-testid="cropImageSelectButton"
                >
                  {buttonState}
                </Button>
            </div>
      </WideModal>
  )
}

CropImageModal.defaultProps = {
  open: false,
  onClose: () => {},
  onCropComplete: () => {},
  aspect: 4 / 4,
  imageFile: ''
}

export default CropImageModal
