import axios from 'axios'

/**
 * @function updateFeatureRequest Used to respond to feature requests made by users
 * @param {string} featureId - id of the feature to respond too
 * @param {string} update - status: 0, 1, 2, 3, 4
 * @param {string} response - Message to the user
 * @param {string} jwt - the JWT token of the user (accessed through UserContext)
 * @returns {Promise} Promise object represents
 */

export const updateFeatureRequest = (featureId, update = 2, response = '', jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/admin/api/update/featurerequest`,
      {
        id: featureId,
        update: update,
        response: response
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  })
}
