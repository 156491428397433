import axios from 'axios'

/**
 * @function getLocation Used to get token for joining the specific gathering
 * @param {string} longitude
 * @param {string} latitude
 * @returns {Promise} Promise object represents
 */
export const getLocation = (longitude, latitude) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/get/location`, {
        lon: longitude,
        lat: latitude
      })
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err)
      })
  })
}
