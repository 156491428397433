import * as React from 'react'
import {
  Stack,
  Button,
  FormControl,
  Typography
} from '@mui/material'
import { Add } from '@mui/icons-material'
import axios from 'axios'

// importing ui
import StandardModal from 'ui/Modals/StandardModal'
import { TextLabel } from 'ui/TextInputs/TextInput'
import CustomPriceFields from 'ui/TextInputs/PriceFieldsInput'
import ImagePicker from 'ui/Custom/ImagePicker/ImagePicker'
import CropImageModal from 'ui/Custom/Modals/CropImageModal'

// importing api
import { authorPresignGet } from 'api/admin/api/music/author/authorPresign'
import { authorAddPost } from 'api/admin/api/music/author/authorAdd'

// importing components
import { UserContext } from '../../Contexts/UserContext'
import { Box } from '@mui/system'

export interface AddMusicAuthorModalProps {
  isOpen: boolean;
  onClose: () => void;
  neighborhoodId?: string;
  onSuccess?: () => void;
}

const AddMusicAuthorModal: React.FC<AddMusicAuthorModalProps> = ({
  isOpen,
  onClose,
  neighborhoodId,
  onSuccess = () => {}
}) => {
  const { jwt_token } = React.useContext(UserContext)
  const [neighborhood, setNeighborhood] = React.useState('')
  const [author, setAuthor] = React.useState('')
  const [isCropImageModalOpen, setIsCropImageModalOpen] = React.useState<File | null>(null)
  const [authorCover, setAuthorCover] = React.useState<File | null>(null)
  const inputErrorInitial = {
    neighborhoodId: null,
    author: null,
    authorCover: null
  }
  const [inputErrors, setInputErrors] = React.useState(inputErrorInitial)
  const [isCreating, setIsCreating] = React.useState(false)

  const _onClose = () => {
    setInputErrors(inputErrorInitial)
    setNeighborhood('')
    setAuthor('')
    setAuthorCover(null)
    setIsCreating(false)
    onClose()
  }

  const validate = () => {
    const newInputErrors = inputErrorInitial

    if (neighborhood.length === 0) {
      newInputErrors.neighborhoodId = 'Neighborhood Id can not be empty!'
    }

    if (author.length === 0) {
      newInputErrors.author = 'Author name can not be empty!'
    }

    if (authorCover === null) {
      newInputErrors.authorCover = true
    }

    setInputErrors(newInputErrors)
    if (Object.values(newInputErrors).find((v) => v !== null)) {
      return false
    } else {
      return true
    }
  }

  const onSubmit = () => {
    if (!validate()) {
      return
    }

    setIsCreating(true)
    let resDataHolder: any = {}
    authorPresignGet(jwt_token)
      .then((resData) => {
        resDataHolder = resData
        return axios.put(resData.body.authorUrl, authorCover)
      })
      .then(() => authorAddPost(jwt_token, {
        picture: resDataHolder.body.author,
        id: resDataHolder.body.id,
        name: author,
        neighbourhood_id: neighborhood
      }))
      .then(() => {
        onSuccess()
        _onClose()
      })
      .catch((err) => {
        setIsCreating(false)
        console.log(err.message)
      })
  }

  React.useEffect(() => {
    if (neighborhoodId) {
      setNeighborhood(neighborhoodId)
    }
  }, [neighborhoodId])

  return (
    <StandardModal
      isOpen={isOpen}
      onClose={_onClose}
      title='Add new Music Author'
    >
      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        style={{
          width: '100%',
          height: '100%'
        }}
      >
        <FormControl fullWidth margin="normal">
          <CustomPriceFields
            label="NeighborHood Id"
            placeholder="Id of the neighborhood to add this author too"
            value={neighborhood}
            inputProps={{ maxLength: '128' }}
            onChange={(e) => setNeighborhood(e.target.value)}
            style={{
              borderRadius: 10
            }}
            error={inputErrors.neighborhoodId}
          />
          {inputErrors.neighborhoodId && (
            <Typography variant="caption" color="error">
              {inputErrors.neighborhoodId}
            </Typography>
          )}
        </FormControl>
        <FormControl fullWidth margin="normal">
          <CustomPriceFields
            label="Auther Name"
            placeholder="Name of the author"
            value={author}
            inputProps={{ maxLength: '128' }}
            onChange={(e) => setAuthor(e.target.value)}
            style={{
              borderRadius: 10
            }}
            error={inputErrors.author}
          />
          {inputErrors.author && (
            <Typography variant="caption" color="error">
              {inputErrors.author}
            </Typography>
          )}
        </FormControl>
        <FormControl
          fullWidth
          margin="normal"
          style={{
            borderRadius: 8,
            borderColor: 'red',
            borderWidth: 1,
            borderStyle: inputErrors.authorCover ? 'solid' : 'none'
          }}
        >
          <TextLabel style={{ marginLeft: 6 }}>
            Author Cover {authorCover ? `(${authorCover.name})` : ''}
          </TextLabel>
          <ImagePicker
            onChange={(e) => setIsCropImageModalOpen(e.target.files[0])}
          >
            <Box
              sx={(theme) => ({
                background: theme.palette.primary.main,
                borderRadius: 1,
                cursor: 'pointer',
                width: 40,
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '8px'
              })}
            >
              <Add htmlColor="white" />
            </Box>
          </ImagePicker>
        </FormControl>
        <Button
          color='primary'
          variant="contained"
          style={{
            width: '100%',
            marginTop: 18
          }}
          onClick={onSubmit}
          disabled={isCreating}
        >
          {isCreating ? 'Creating' : 'Create'}
        </Button>
      </Stack>
      <CropImageModal
        open={Boolean(isCropImageModalOpen)}
        imageFile={isCropImageModalOpen}
        onCropComplete={(imgBlog) => {
          const imgFile = new File([imgBlog], isCropImageModalOpen.name)
          setAuthorCover(imgFile)
          setIsCropImageModalOpen(null)
        }}
      />
    </StandardModal>
  )
}

export default AddMusicAuthorModal
