import * as React from 'react'
import {
  FormControl,
  TextField,
  Stack,
  Typography,
  Checkbox,
  Button,
} from '@mui/material'
import { AttachMoney } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

// importing ui
import StandardModal from 'ui/Modals/StandardModal'

// importing api
import { subscriptionPlanCreate } from 'api/admin/api/subscription'

// importing component
import { UserContext } from '../../../Contexts/UserContext'

// constant data
const defaultPlanDetails = {
  'becomePopularInNeighborhood': -1,
  'watchAllReviews': -1,
  'hideVideosFromBusinessPublicPage': 0,
  'downloadVideoWithWatermark': -1,
  'downloadVideoWithoutWatermark': 0,
  'respondToReview': -1,
  'createOffer': 3,
  'videoStorageLimitation': 100,
  'analytics': 1, // 1 -> basic, 2 -> advance, 3 -> advance + extra
  'bulkSms': 0,
  'bulkEmails': 1000,
  'organicCustomerReviewsAssured': 0,
  'socialMediaMarketing': 0
}

export interface CreateSubscriptionPlanProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateSubscriptionPlan: React.FC<CreateSubscriptionPlanProps> = (props) => {
  const { jwt_token, user } = React.useContext(UserContext)
  const { enqueueSnackbar } = useSnackbar()

  const planDetailsRef = React.useRef(null)
  // component states
  const [name, setName] = React.useState('')
  const [amount, setAmount] = React.useState<number>(null)
  const [currencyCode, setCurrencyCode] = React.useState('USD')
  const [days, setDays] = React.useState<number>(null)
  const [isCouponApplicable, setIsCouponApplicable] = React.useState(false)
  const inputErrorsDefault = {
    name: null,
    amount: null,
    currencyCode: null,
    days: null,
  }
  const [inputErrors, setInputErrors] = React.useState(inputErrorsDefault)
  const [creating, setCreating] = React.useState(false)

  const validate = () => {
    const newInputErrors = inputErrorsDefault

    if (name.length === 0) {
      newInputErrors.name = "Plan name can't be empty."
    }

    if (amount === null || amount <= 0) {
      newInputErrors.name = "Amount can't be zero."
    }

    if (currencyCode.length !== 3) {
      newInputErrors.currencyCode = "Invalid currency code."
    }

    if (days === null || days <= 0) {
      newInputErrors.days = "Days can't be zero."
    }

    setInputErrors(newInputErrors)
    if (Object.values(newInputErrors).find((val) => val !== null)) {
      return false
    } else {
      return true
    }
  }

  const onCreatePlan = () => {
    if (creating) {
      return
    }

    if (!validate()) {
      return
    }

    let planDetails = JSON.parse(planDetailsRef.current.value)

    setCreating(true)
    subscriptionPlanCreate(
      {
        admin_id: user.uid,
        admin_name: user.name,
        plan: name,
        amount: amount,
        currency: currencyCode,
        is_coupon_applicable: isCouponApplicable,
        subscription_span: days,
        plan_details: planDetails
      },
      jwt_token
    )
      .then(() => {
        enqueueSnackbar('Subscription plan is created!', {
          variant: 'success'
        })
        props.onClose()
      })
      .catch((err) => console.log(err.message))
      .finally(() => setCreating(false))
  }

  return (
    <StandardModal
      title="Create Subscription Plan"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <Stack
        direction="column"
        sx={{
          paddingTop: 1,
          paddingBottom: 1
        }}
      >
        <FormControl fullWidth margin="dense">
          <TextField
            variant="outlined"
            label="Name*"
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}    
            style={{ marginBottom: 12 }}
            size="small"
            error={inputErrors.name}
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <TextField
            variant="outlined"
            label="Amount (USD)*"
            value={amount}
            type="number"
            onChange={(e) => {
              setAmount(Number(e.target.value))
            }}
            style={{ marginBottom: 12 }}
            size="small"
            InputProps={{
              endAdornment: <AttachMoney />
            }}
            error={inputErrors.amount}
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <TextField
            variant="outlined"
            label="Currency Code*"
            value={currencyCode}
            onChange={(e) => {
              if (e.target.value.length > 3) {
                return
              }
              setCurrencyCode(e.target.value.toUpperCase())
            }}    
            style={{ marginBottom: 12 }}
            size="small"
            error={inputErrors.currencyCode}
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <TextField
            variant="outlined"
            label="Subscription span (in days)*"
            value={days}
            type="number"
            onChange={(e) => {
              setDays(Number(e.target.value))
            }}
            style={{ marginBottom: 12 }}
            size="small"
            error={inputErrors.days}
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <Typography variant="h6">
            Add Plan Details
          </Typography>
          <Typography variant="caption" component="span">
            -1 is unlimited, 0 is no access, 1 and above indicates respective units of access
          </Typography>
          <TextField
            inputRef={planDetailsRef}
            variant="outlined"
            defaultValue={JSON.stringify(defaultPlanDetails, undefined, 4)}
            type="string"
            style={{ marginBottom: 12 }}
            size="small"
            multiline
            rows={8}
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography>
              Can coupons be applied?
            </Typography>
            <Checkbox
              checked={isCouponApplicable}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setIsCouponApplicable(event.target.checked);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Stack>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={onCreatePlan}
          sx={{
            marginTop: 1
          }}
        >
          Create
        </Button>
      </Stack>
    </StandardModal>
  )
}

export default CreateSubscriptionPlan
