import {
  Stack,
  Typography,
  Button,
  Fade,
  Box,
  FormControl
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import dayjs from 'dayjs'

import DatePicker from 'ui/Pickers/DatePicker/DatePicker'

// importing components
import { UserContext } from '../../Contexts/UserContext'

// importing api
import { adminApiAnalyticsBusinessCampaign } from 'api/admin/api/analytics'

// importing components
import BusinessCampaignFlow from '../../Components/modals/BusinessCampaignFlow/BusinessCampaignFlow'

const buttonsTimeline = [
  {
    text: 'Last Two Days',
    value: 2
  },
  {
    text: 'Last Week',
    value: 7
  },
  {
    text: 'This Month',
    value: 31
  },
  {
    text: 'Past Year',
    value: 365
  },
  {
    text: 'Select Range',
    value: 365,
    key: 'customRangeSelector'
  }
]

const BusinessCampaign = (props) => {
  const { jwt_token } = useContext(UserContext)

  const [loading, setLoading] = useState(true)
  const [businesses, setBusinesses] = useState({})
  const [counts, setCounts] = useState({})
  const [curPage, setCurPage] = useState(1)
  const businessFlowInit = {
    isOpen: false,
    title: '',
    email: '',
    openedTimings: [Date.now(), Date.now(), Date.now(), Date.now()]
  }
  const [businessFlowState, setBusinessFlowState] = useState(businessFlowInit)
  const [hasRange, setHasRange] = useState(false)
  const [startDate, setStartDate] = useState(dayjs().subtract(buttonsTimeline[0].value, 'day').toISOString())
  const [endDate, setEndDate] = useState(dayjs().toISOString())

  const fetchBusinessesAnalyticsDetails = (p = 1, sDate = startDate, eDate = endDate) => {
    setLoading(true)
    adminApiAnalyticsBusinessCampaign(
      dayjs(sDate).toISOString(),
      dayjs(eDate).toISOString(),
      jwt_token
    )
      .then((resBody) => {
        setCounts(resBody.Count)
        const p = 1
        const obj = {}
        if (resBody.Data !== null) {
          for (let i = 0; i < resBody.Data.length; i = i + 100) {
            obj[p] = resBody.Data.slice(i, i + 101)
          }
        }
        setBusinesses(obj)
        setLoading(false)
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchBusinessesAnalyticsDetails(1)
  }, [])

  return (
    <Stack
      sx={{
        margin: 1
      }}
    >
      <Stack direction="column" sx={{ marginBottom: 2 }}>
        <Stack direction="row" sx={{ marginBottom: 2 }}>
          {buttonsTimeline.map((button) => {
            return (
              <Button
                key={button.value}
                onClick={() => {
                  if (button.key === 'customRangeSelector') {
                    setHasRange(true)
                  } else {
                    setStartDate(dayjs().subtract(button.value, 'day').format())
                    setEndDate(dayjs().toISOString())
                    setBusinesses({})
                    setHasRange(false)
                    fetchBusinessesAnalyticsDetails(
                      1,
                      dayjs().subtract(button.value, 'day').toISOString(),
                      dayjs().toISOString()
                    )
                  }
                }}
                variant="outlined"
                disableElevation
                sx={{
                  marginRight: 1
                }}
              >
                {button.text}
              </Button>
            )
          })}
        </Stack>
        {hasRange && (
          <Fade in={hasRange} out={hasRange === false}>
            <Box sx={{ marginBottom: 2 }}>
              <Stack direction="row">
                <FormControl margin="normal">
                  <Typography variant="h6">
                    Start date
                  </Typography>
                  <DatePicker
                    value={startDate}
                    onChange={(e) => setStartDate(dayjs(e).format())}
                    placeholder='mm/dd/yyyy'
                    // error={inputErrors.dateStart}
                  />
                </FormControl>
                <FormControl margin="normal" sx={{ marginLeft: 1 }}>
                  <Typography variant="h6">
                    End date
                  </Typography>
                  <DatePicker
                    value={endDate}
                    onChange={(e) => setEndDate(dayjs(e).format())}
                    placeholder='mm/dd/yyyy'
                    // error={inputErrors.dateStart}
                  />
                </FormControl>
              </Stack>
              <Button
                onClick={() => {
                  setBusinesses({})
                  fetchBusinessesAnalyticsDetails(1)
                }}
                variant="contained"
                disableElevation
              >
                Set Dates
              </Button>
            </Box>
          </Fade>
        )}
        <Typography variant="h5" sx={{ marginTop: 2, marginBottom: 1 }} >
          Status for: {dayjs(startDate).format('MM/DD/YYYY')} - {dayjs(endDate).format('MM/DD/YYYY')}
        </Typography>
        <Typography variant="h4">
          Campaign Email Opened: {counts.email_opened}
        </Typography>
        <Typography variant="h4">
          Campaign Link was opened: {counts.landing_page_opened}
        </Typography>
        <Typography variant="h4">
          Business clicked on sign up: {counts.signup_clicked}
        </Typography>
        <Typography variant="h4">
          Business successfully signed up: {counts.successful_signup}
        </Typography>
      </Stack>
      <Stack direction="column" sx={{ marginTop: 2, minHeight: '90vh' }}>
        <DataGrid
          rows={businesses[curPage] || []}
          getRowId={(r) => `${r.slug} + ${r.email}`}
          columns={[
            {
              field: 'name',
              headerName: 'Business Name',
              minWidth: 180,
              align: 'left'
            },
            {
              field: 'email',
              headerName: 'Email',
              minWidth: 240,
              align: 'left'
            },
            {
              field: 'email_opened',
              headerName: 'Email Opened',
              minWidth: 180,
              type: 'boolean'
            },
            {
              field: 'landing_page_opened',
              headerName: 'Landing Page Opened',
              minWidth: 240,
              type: 'boolean'
            },
            {
              field: 'signup_clicked',
              headerName: 'Sign Up Clicked',
              minWidth: 180,
              type: 'boolean'
            },
            {
              field: 'successful_signup',
              headerName: 'Signed Up',
              minWidth: 180,
              type: 'boolean'
            },
            {
              field: 'slug',
              headerName: 'Slug',
              minWidth: 180,
              align: 'left'
            },
            {
              field: 'flow',
              headerName: 'Flow',
              minWidth: 180,
              renderCell: ({ row }) => {
                return (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => {
                      setBusinessFlowState({
                        isOpen: true,
                        title: row.name,
                        email: row.email,
                        openedTimings: [
                          row.email_opened_time,
                          row.landing_page_opened_time,
                          row.signup_clicked_time,
                          row.successful_signup_time
                        ]
                      })
                    }}
                  >
                    Open
                  </Button>
                )
              }
            }
          ]}
          pageSize={100}
          components={{
            Toolbar: GridToolbar
          }}
          style={{
            overflowX: 'auto',
            alignContent: 'left',
            textAlign: 'left'
          }}
          onPageChange={(p) => {
            if (loading) {
              return
            }
            setCurPage(p)
          }}
          loading={loading}
          page={0}
          checkboxSelection
        />
      </Stack>
      <BusinessCampaignFlow
        title={businessFlowState.title}
        email={businessFlowState.email}
        openedTimings={businessFlowState.openedTimings}
        isOpen={businessFlowState.isOpen}
        onClose={() => setBusinessFlowState(businessFlowInit)}
      />
    </Stack>
  )
}

export default BusinessCampaign
