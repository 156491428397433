import axios from 'axios'

/**
 * @function authorPresignGet
 * @param {string} jwt - the JWT token of the user (accessed through UserContext)
 * @returns {Promise}
 */

export const authorPresignGet = (jwt) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_ADMIN_URL}/admin/api/music/author/presign`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }
      )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  })
}
