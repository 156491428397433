import axios from 'axios'

/**
 * @function changeCustomUrlPost Used by admin to change a business' custom URL
 * @param {string} jwt - the JWT token of the admin
 * @param {string} businessId - the id of the business whose URL is to be changed
 * @param {string} newUrl - the new custom URL for the business
 * @returns {Promise} Promise object represents
 */

export const changeCustomUrlPost = (jwt, businessId, newUrl) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_ADMIN_URL}/admin/api/business/url`,
        {
          business_id: businessId,
          custom_url: newUrl
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }
      )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  })
}
