import React, { useCallback, useContext, useEffect, useState } from 'react'
import { UserContext } from '../../Contexts/UserContext'
import { DataGrid, GridOverlay, GridToolbar } from '@mui/x-data-grid'
import { globalTranslate } from '../../config/tables'
import { Button, createTheme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useModal } from '../../Contexts/ModalContext'
import Create from './Create'
import { useSnackbar } from 'notistack'
import { adminGetAll } from 'api/admin/api/getAll'
import { blockToggleGet } from 'api/admin/api/blockToggle'

// importing api
import { adminOneTimeLogin } from 'api/admin/api/oneTimeLogin'

// importing helpers
import { getBusinessDomainURL } from 'helpers/url'

const translator = (translate = {}, item) =>
  Object.entries(translate).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [key]:
				typeof value === 'function'
				  ? value(item[key])
				  : typeof value === 'object'
				    ? value[item[key]]
				    : value
    }),
    {}
  )

export default function GetAll ({
  fields,
  translate,
  endpoints: { getAll, blockToggle, create, isAdminBusinessLogin },
  label: { singular, plural },
  create_modal,
  openCreateModal,
  rowId = 'id'
}) {
  const { jwt_token } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [page, setPage] = useState(0)
  const [maxCount, setMaxCount] = useState(Infinity)
  const [lastLoadedPage, setLastLoadedPage] = useState(0)
  const limit = 100
  const { openModal } = useModal()
  const { enqueueSnackbar } = useSnackbar()

  const getData = useCallback(async (p = -1) => {
    setLoading(true)
    try {
      let nextPage = page + 1
      if (lastLoadedPage > page && data?.[getAll] && p === -1) {
        return
      }
      if (!data?.[getAll]) {
        nextPage = 1
      }

      const thisData = await adminGetAll(getAll, jwt_token, {
        page: p === -1 ? nextPage : p + 1
      })

      if (!thisData.body && p === -1) {
        return setMaxCount((nextPage - 1) * limit)
      }
      if (thisData.body.length < limit && p === -1) {
        setMaxCount((nextPage - 1) * limit + thisData.body.length)
      }
      if (p === -1) {
        setData((p) => ({
          ...p,
          [getAll]: [
            ...(p[getAll] || []),
            ...thisData.body.map((item, idx) => ({
              sno: Math.floor((nextPage - 1) * limit) + idx + 1,
              ...item,
              ...translator(globalTranslate, item),
              ...translator(translate, item)
            }))
          ]
        }))
        setLastLoadedPage(page + 1)
      } else {
        const curData = (data[getAll] || [])
        const curNewData = [
          ...curData.slice(0, limit * (page)),
          ...thisData.body.map((item, idx) => ({
            sno: Math.floor((p) * limit) + idx + 1,
            ...item,
            ...translator(globalTranslate, item),
            ...translator(translate, item)
          }))
        ]
        const newData = {
          ...data,
          [getAll]: curNewData
        }
        setData(newData)
        setLastLoadedPage(p + 1)
      }
    } catch (err) {
      console.log(err.message)
    } finally {
      setLoading(false)
    }
  }, [getAll, page])

  useEffect(() => {
    setLoading(true)
    getData().finally(() => setLoading(false))
  }, [getData])

  useEffect(() => {
    setPage(0)
    setLastLoadedPage(0)
    setMaxCount(Infinity)
  }, [getAll])

  return (
    <div
      style={{
        height: 800,
        width: '100%',
        overflowX: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 20
      }}
    >
      {(create || create_modal) && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            onClick={() => {
              openModal(
                create_modal || (() => <Create link={create} />)
              )
            }}
          >
            Create {singular}
          </Button>
        </div>
      )}
      {openCreateModal && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            onClick={openCreateModal}
          >
            Create {singular}
          </Button>
        </div>
      )}
      <DataGrid
        rows={data[getAll] || []}
        columns={[
          ...fields,
          ...(blockToggle
            ? [
                {
                  field: 'block_id',
                  headerName: 'Block/Unblock',
                  minWidth: 200,
                  align: 'left',
                  renderCell: (params) => {
                    const isBlocked = params.row.is_blocked !== 'Active'
                    return (
                      <Button
                        variant="outlined"
                        onClick={async () => {
                          blockToggleGet(
                            jwt_token,
                            blockToggle(params.id, !isBlocked)
                          )
                            .then((res) => {
                              enqueueSnackbar(`${singular} successfully blocked.`, {
                                variant: 'success'
                              })
                              getData(page)
                            })
                            .catch((err) => {
                              // console.log(err);
                              enqueueSnackbar(err.message, {
                                variant: 'error'
                              })
                            })
                        }}
                      >
                        {isBlocked
                          ? 'Unblock'
                          : 'Block'}{' '}
                        {singular}
                      </Button>
                    )
                  }
                }
              ]
            : []),
          ...(isAdminBusinessLogin
            ? [
                {
                  field: 'login',
                  headerName: 'Login',
                  minWidth: 150,
                  align: 'left',
                  renderCell: (entry) => (
                <Button
                color="primary"
                variant='outlined'
                onClick={() => {
                  adminOneTimeLogin(entry.row.id, jwt_token)
                    .then((body) => {
                      window.open(getBusinessDomainURL(`/admin-login?id=${body.id}&passcode=${body.passcode}`))
                    })
                    .catch((err) => console.log(err))
                }}
                >
                Login
                </Button>
                  )
                }
              ]
            : [])
        ]}
        pageSize={limit}
        rowsPerPageOptions={[limit]}
        components={{
          Toolbar: GridToolbar,
          NoRowsOverlay: () => (
            <CustomNoRowsOverlay plural={plural} />
          )
        }}
        style={{
          overflowX: 'auto',
          alignContent: 'left',
          textAlign: 'left'
        }}
        onPageChange={(p) => {
          if (loading) {
            return
          }
          setPage(p)
        }}
        rowCount={maxCount}
        loading={loading}
        page={page}
        checkboxSelection
        getRowId={(row) => row[rowId]}
      />
    </div>
  )
}

const defaultTheme = createTheme()
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        flexDirection: 'column',
        '& .ant-empty-img-1': {
          fill:
						theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
        },
        '& .ant-empty-img-2': {
          fill:
						theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
        },
        '& .ant-empty-img-3': {
          fill:
						theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
        },
        '& .ant-empty-img-4': {
          fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
        },
        '& .ant-empty-img-5': {
          fillOpacity:
						theme.palette.mode === 'light' ? '0.8' : '0.08',
          fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
        }
      },
      label: {
        marginTop: theme.spacing(1)
      }
    }),
  {
    defaultTheme
  }
)

export function CustomNoRowsOverlay ({ plural }) {
  const classes = useStyles()
  return (
    <GridOverlay className={classes.root}>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g
            className="ant-empty-img-4"
            transform="translate(149.65 15.383)"
          >
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <div className={classes.label}>No {plural} Available</div>
    </GridOverlay>
  )
}
