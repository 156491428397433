import axios from 'axios'

/**
 * @function funcName funcName's description
 * @param {string} email - what the param does {send as undefined for now, backend issue}
 * @param {string} token - user's jwt token
 * @returns {Promise} Promise object represents
 */
export const signupInit = (email, token) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/signup/init`, {
      email,
      id_token: token
    })
      .then(() => resolve(true))
      .catch((err) => {
        reject(err)
      })
  })
}
