import * as React from 'react'
import {
  Stack,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'

// importing ui
import StandardModal from 'ui/Modals/StandardModal'
import TextAreaInput from 'ui/TextInputs/TextAreaInput'

// importing api
import { updateFeatureRequest } from 'api/admin/api/update/featureRequest'

// importing components
import { UserContext } from '../../Contexts/UserContext'

export const mappedStatusDetails = new Map([
  [0, {
    text: 'New',
    color: 'primary'
  }],
  [1, {
    text: 'Under Review',
    color: 'ghost'
  }],
  [2, {
    text: 'Approved',
    color: 'primary'
  }],
  [3, {
    text: 'Closed (Duplicate)',
    color: 'secondary'
  }],
  [4, {
    text: 'Closed (Out of scope)',
    color: 'secondary'
  }]
])

export interface BusinessOnboardingModalProps {
  isOpen: boolean;
  onClose: () => void;
  featureId: string;
}

const BusinessOnboardingModal: React.FC<BusinessOnboardingModalProps> = ({
  isOpen,
  onClose,
  featureId
}) => {
  const { jwt_token } = React.useContext(UserContext)
  const [response, setResponse] = React.useState('')
  const [status, setStatus] = React.useState(1)

  const onSubmit = () => {
    updateFeatureRequest(
      featureId,
      status,
      response,
      jwt_token
    )
      .then(() => {
        onClose()
        location.reload()
      })
      .catch((err) => console.log(err))
  }

  return (
    <StandardModal
      isOpen={isOpen}
      onClose={onClose}
      title='Respond to Feature Request'
    >
      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        style={{
          width: '100%',
          height: '100%'
        }}
      >
        <FormControl fullWidth margin="normal">
          <TextAreaInput
            multiline
            rows={3}
            label="Response Text"
            placeholder="Give your feedback to the user!"
            value={response}
            inputProps={{ maxLength: '255' }}
            onChange={(e) => setResponse(e.target.value)}
            style={{
              borderRadius: 10
            }}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="status">Status</InputLabel>
          <Select
            labelId="status"
            value={status}
            label="Status"
            onChange={(e) => setStatus(Number(e.target.value))}
          >
            {Array.from(mappedStatusDetails.keys()).map((key) => {
              const details = mappedStatusDetails.get(key)
              return (
                <MenuItem
                  key={key}
                  value={key}
                  color={details.color}
                >
                  {details.text}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <Button
          color="primary"
          variant="contained"
          style={{
            width: '100%',
            marginTop: 18
          }}
          onClick={onSubmit}
        >
          Submit
        </Button>
      </Stack>
    </StandardModal>
  )
}

export default BusinessOnboardingModal
