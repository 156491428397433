import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export interface LineChartData {
  x: string | number;
  y: number;
}

export interface LineChartProps {
  data: LineChartData[];
}

const LineChart: React.FC<LineChartProps> = (props) => {
  return (
    <Line
      options={{
        responsive: true,
        scales: {
          y: {
            display: false
          },
          x: {
            display: false
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }}
      data={{
        datasets: [{
          data: props.data,
          borderColor: '#6D9674',
          tension: 0.01 * props.data.length,
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          pointRadius: 10
        }]
      }}
    />
  )
}

export default LineChart
