import firebase from 'firebase/app'
import 'firebase/auth'

const app = firebase.initializeApp(
  {
    apiKey: process.env.REACT_APP_BUSINESS_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_BUSINESS_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_BUSINESS_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_BUSINESS_FIREBASE_STORAGE_BUCKET,
    messagingSenderId:
			process.env.REACT_APP_BUSINESS_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_BUSINESS_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_BUSINESS_FIREBASE_MEASUREMENT_ID
  },
  'business'
)

export const auth = firebase.auth()
// export const analytics = app.analytics();
export const google_auth = new firebase.auth.GoogleAuthProvider()
export const facebook_auth = new firebase.auth.FacebookAuthProvider()
export const apple_auth = new firebase.auth.OAuthProvider('apple.com')
// export const popup = app.signInWithPopup();

export default app
