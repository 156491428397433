import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import axios from 'axios'
import { UserContext } from '../../../Contexts/UserContext'

// importing ui
import CreateBusinessModal from 'ui/Custom/Modals/CreateBusinessModal/CreateBusinessModal'

function AuthModal ({ isOpen, onClose }) {
  const { jwt_token } = useContext(UserContext)

  const { enqueueSnackbar } = useSnackbar()

  const createApi = (business) => {
    // don't remove
    business.address.address_line = business.address.address
    business.business.address = business.address

    axios.post(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/admin/api/business/create`, business, {
      headers: {
        Authorization: `Bearer ${jwt_token}`
      }
    })
      .then((res) => {
        enqueueSnackbar('Business created successfully.', {
          variant: 'success'
        })
        onClose()
      })
      .catch((err) => {
        console.log(err.message)
        enqueueSnackbar('Could not sign up. Please try again!', {
          variant: 'error'
        })
      })
  }
  return (
    <CreateBusinessModal
      isOpen={isOpen}
      onClose={onClose}
      data={{}}
      next={(business) => createApi(business)}
      setOtpCountry={(c) => {}}
    />
  )
}

export default AuthModal
