import React from 'react'
import UserContextProvider from './Contexts/UserContext'
// import { ThemeProvider } from "@mui/system";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { SnackbarProvider } from 'notistack'
import Login from './pages/Login/Login'
import Dashboard from './pages/Dashboard/Dashboard'
import { tables } from './config/tables'
import { ModalProvider } from './Contexts/ModalContext'
import ThemeProvider from 'ui/Theming/ThemeProvider'
import './App.css'

// importing context
import GlobalFuncHOC from './Contexts/GlobalFuncHOC'

function App () {
  return (
    <CookiesProvider>
      <UserContextProvider>
        <ThemeProvider>
          <GlobalFuncHOC>
            <SnackbarProvider
              maxSnack={2}
              autoHideDuration={1500}
              preventDuplicate
              hideIconVariant
            >
              <ModalProvider>
                <BrowserRouter>
                  <Switch>
                    <Route
                      exact
                      path={['/login', '/signup']}
                      component={Login}
                    />
                    <Route
                      path={[
                        '/',
                        ...tables().map((i) => i.path)
                      ]}
                      component={Dashboard}
                    />

                  </Switch>
                </BrowserRouter>
              </ModalProvider>
            </SnackbarProvider>
          </GlobalFuncHOC>
        </ThemeProvider>
      </UserContextProvider>
    </CookiesProvider>
  )
}

export default App
