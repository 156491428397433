import React from 'react'
import MobileDatePicker from '@mui/lab/MobileDatePicker'

// importing base component
import PriceFieldsInput from '../../TextInputs/PriceFieldsInput'
import DateAdapter from '@mui/lab/AdapterDayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { Typography } from '@mui/material'

export interface DatePickerProps {
  dense?: boolean;
  value: string;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  onChange: (e: any) => void;
}

const DatePicker: React.FC<DatePickerProps> = (props) => {
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <MobileDatePicker
        value={props.value}
        onChange={props.onChange}
        orientation='portrait'
        renderInput={(params) => {
          return (
            <>
                <PriceFieldsInput
                  {...params}
                  variant='outlined'
                  inputProps={{
                    ...params.inputProps,
                    style: props.dense && { padding: '8px 12px' } // For dense
                  }}
                />
                <Typography style={{ marginLeft: 16 }} variant="caption">
                  {props.placeholder}
                </Typography>
            </>
          )
        }}
        {...props}
      />
    </LocalizationProvider>
  )
}

export default DatePicker
