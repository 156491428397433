import React from 'react'

// import base components
import TextInput, { TextLabel } from './TextInput'

export interface CustomTextAreaProps {
  label: string;
  name: string;
  inputProps: any;
}

const CustomTextArea: React.FC<any> = ({
  label,
  name,
  inputProps,
  ...props
}) => {
  return (
    <>
      <TextLabel
        htmlFor={name}
        style={{
          marginLeft: 6,
          fontSize: 14,
          fontWeight: 600
        }}
      >
        {label}
      </TextLabel>
      <TextInput
        name={name}
        multiline
        color="#8aa6de"
        inputProps={
        inputProps || {
          maxLength: '200'
        }
        }
        {...props}
      />
    </>
  )
}

export default CustomTextArea
