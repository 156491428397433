import axios from 'axios'

/**
 * @function approxGeneral Gets the number of nearcasts created
 * @param {string} startDate - start date in string
 * @param {string} endDate - end date in string
 * @param {string} jwt - the JWT token of the user (accessed through UserContext)
 * @returns {Promise} Promise object represents
 */

export const analyticsActiveUsersDistinct = (startDate, endDate, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/admin/api/analytics/active-user/distinct`, {
      from: startDate,
      to: endDate
    }, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    )
      .then((res) => resolve(res.data.body.active_accounts[0]))
      .catch((err) => reject(err))
  })
}
