import React from 'react'
import { InputBase, TextField } from '@mui/material'
import { withStyles } from '@mui/styles'

const bg = () => (props) => `${props.color}14`
const borderFocus = () => (props) => `1px solid ${props.color}41`

const TextInput = withStyles({
  root: {
    'label + &': {
      marginTop: 8
    }
  },
  input: {
    borderRadius: 5,
    position: 'relative',
    border: '1px solid transparent',
    backgroundColor: bg('color'),
    fontSize: 16,
    padding: '12px 16px',
    fontFamily: 'Avenir Next',
    '&:focus': {
      border: borderFocus('color')
    }
  },
  error: {
    borderRadius: 5,
    border: '1px solid #ff000041',
    backgroundColor: '#ff000014'
  }
})(({ color, ...props }) => <InputBase {...props} />)

export function TextLabel ({ children, style = {}, ...props }) {
  return (
		<label style={{ ...style, fontSize: 16, fontWeight: 600 }} {...props}>
			{children}
		</label>
  )
}

export const CustomTextField = ({ color, ...props }) => {
  const styles = {
    root: {
      backgroundColor: '#F6F8FC'
    }
  }
  return (
    <TextField
      color={color}
      variant="filled"
      InputProps={{
        disableUnderline: true,
        className: styles.root
      }}
      {...props}
    />
  )
}

export default TextInput
