import React from 'react'
import GoogleButton from '../../pages/Login/google-btn.png'
import '../../firebase'
import firebase from 'firebase/app'
import 'firebase/auth'

function GoogleSignin ({ onSuccess, onError }) {
  const googleAuth = () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    firebase.auth().signInWithPopup(provider).then(() => {
      firebase.auth().currentUser.getIdToken(true).then((token) => {
        onSuccess(token)
      })
    }).catch((error) => {
      // console.log(error);
      onError()
    })
  }

  return (
        <img src={GoogleButton}
            onClick={googleAuth}
            alt="Continue with Google"
            draggable="false"
            width="200px"
            style={
                { cursor: 'pointer' }
            }/>
  )
}

export default GoogleSignin
