import React, { createContext, useEffect, useReducer, useState } from 'react'
import Loading from '../Components/Loading/Loading'
import { useCookies } from 'react-cookie'

// importing apis
import { axiosResponseInterceptorBuilder, axiosEjectInterceptor } from 'api/index'

export const UserContext = createContext()

const initialState = {
  jwt_token: null,
  user: null
}

const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState)
  const [loading, setLoading] = useState(true)
  const [cookies, setCookie, removeCookie] = useCookies(['jwt_token', 'user'])
  const detectDomain = () => window.location.hostname
  const cookieOptions = {
    path: '/',
    domain: detectDomain()
  }

  const login = (data) => {
    if (data.jwt_token) {
      dispatch({ type: 'SET_JWT_TOKEN', data: data.jwt_token })
      localStorage.setItem('jwt_token', data.jwt_token)
      setCookie('jwt_token', data.jwt_token, cookieOptions)
    }
    if (data.user) {
      dispatch({ type: 'SET_USER', data: data.user })
      localStorage.setItem('user', JSON.stringify(data.user))
      setCookie('user', data.user, cookieOptions)
    }
  }

  const logout = () => {
    dispatch({ type: 'SET_LOGOUT' })
    const cookieNames = Object.keys(cookies)
    cookieNames.forEach((cookieName) => {
      removeCookie(cookieName, cookieOptions)
      localStorage.clear()
    })
  }

  const autoLogin = async () => {
    dispatch({
      type: 'SET_JWT_TOKEN',
      // data: cookies.jwt_token,
      data: localStorage.getItem('jwt_token')
    })
    if (cookies.user) {
      dispatch({
        type: 'SET_USER',
        data: cookies.user
      // data: JSON.parse(localStorage.getItem("user")),
      })
    }
  }

  useEffect(() => {
    if (cookies.jwt_token) {
      autoLogin().then(() => {
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const responseInterceptorId = axiosResponseInterceptorBuilder(
      (res) => {
        return res
      },
      (error) => {
        const orignalRequest = error.config
        if (
          error.response &&
          error.response.status === 401 &&
          orignalRequest.headers.Authorization
        ) {
          return logout()
        }
        return Promise.reject(error)
      }
    )
    return () => {
      axiosEjectInterceptor('', responseInterceptorId)
    }
  }, [state.jwt_token])

  const value = {
    ...state,
    login,
    logout
  }

  if (loading) {
    return <Loading />
  }

  return <UserContext.Provider value={value}>{children} </UserContext.Provider>
}

export default UserContextProvider

const UserReducer = (state, action) => {
  switch (action.type) {
    case 'SET_JWT_TOKEN':
      return {
        ...state,
        jwt_token: action.data
      }
    case 'SET_USER':
      return {
        ...state,
        user: action.data
      }
    case 'SET_LOGOUT':
      return {
        ...state,
        jwt_token: null,
        user: null
      }

    default:
      return {
        ...state
      }
  }
}
