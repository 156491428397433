import {
  Stack,
  Button,
  Typography,
  FormControl,
  Fade,
  Box
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

// importing components
import { UserContext } from '../../Contexts/UserContext'
import { GlobalFuncContext } from '../../Contexts/GlobalFuncHOC'

// importing ui
import InsightGraphCard from 'ui/Custom/Stats/InsightGraphCard/InsightGraphCard'
import DatePicker from 'ui/Pickers/DatePicker/DatePicker'

// importing api
import {
  adminBusinessAnalyticsTotalScans,
  adminBusinessAnalyticsTotalViews,
  adminBusinessAnalyticsTotalCreated,
  adminBusinessAnalyticsTotalOfferCreated
} from 'api/admin/api/business/analytics/offer'
import { adminBusinessAnalyticsDetails } from 'api/admin/api/business/analytics/details'
import { adminOneTimeLogin } from 'api/admin/api/oneTimeLogin'

// importing helpers
import { getUserDomainURL, getBusinessDomainURL } from 'helpers/url'

const buttonsTimeline = [
  {
    text: 'Last Two Days',
    value: 2
  },
  {
    text: 'Last Week',
    value: 7
  },
  {
    text: 'This Month',
    value: 31
  },
  {
    text: 'Past Year',
    value: 365
  },
  {
    text: 'Select Range',
    value: 365,
    key: 'customRangeSelector'
  }
]

const AllBusinessReviews = (props) => {
  const { jwt_token } = useContext(UserContext)
  const { openBusinessOfferDetails } = useContext(GlobalFuncContext)

  const [loading, setLoading] = useState(true)
  const [businesses, setBusinesses] = useState({})
  const [curPage, setCurPage] = useState(1)

  const [hasRange, setHasRange] = useState(false)
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'day').toISOString())
  const [endDate, setEndDate] = useState(dayjs().toISOString())
  const [reviewInsightGraphState, setReviewInsightGraphState] = useState([])

  const businessReviewsInsightData = (startTime, endTime) => {
    return [
      {
        title: 'Total QR Scanned',
        helper: 'The total number of times an Offer QR code was scanned by a customer',
        startTime: dayjs(startTime).toISOString(),
        endTime: dayjs(endTime).toISOString(),
        dataRequest: async () => {
          return new Promise((resolve, reject) => {
            adminBusinessAnalyticsTotalScans(
              dayjs(startTime).toISOString(),
              dayjs(endTime).toISOString(),
              jwt_token
            )
              .then((resBody) => resolve(resBody))
              .catch((err) => reject(err))
          })
        }
      },
      {
        title: 'Total Reviews Views',
        helper: 'The total number of views that all reviews got.',
        startTime: dayjs(startTime).toISOString(),
        endTime: dayjs(endTime).toISOString(),
        dataRequest: async () => {
          return new Promise((resolve, reject) => {
            adminBusinessAnalyticsTotalViews(
              dayjs(startTime).toISOString(),
              dayjs(endTime).toISOString(),
              jwt_token
            )
              .then((resData) => resolve(resData))
              .catch((err) => reject(err))
          })
        }
      },
      // {
      //   title: 'Total Users Acquired',
      //   helper: 'Total number of anonymous users who signed in after creating a review.',
      //   startTime: dayjs().subtract(days, 'day').toISOString(),
      //   endTime: dayjs().toISOString(),
      //   dataRequest: async () => {
      //     return tt
      //     // return new Promise((resolve, reject) => {
      //     //   businessAnalyticsReviewsViews(
      //     //     dayjs().subtract(days, 'day').toISOString(),
      //     //     dayjs().toISOString(),
      //     //     jwtToken
      //     //   )
      //     //     .then((resData) => resolve(resData))
      //     //     .catch((err) => reject(err))
      //     // })
      //   }
      // },
      {
        title: 'Total Offers Created',
        helper: 'The total number of offers created by the businesses.',
        startTime: dayjs(startTime).toISOString(),
        endTime: dayjs(endTime).toISOString(),
        dataRequest: async () => {
          return new Promise((resolve, reject) => {
            adminBusinessAnalyticsTotalOfferCreated(
              dayjs(startTime).toISOString(),
              dayjs(endTime).toISOString(),
              jwt_token
            )
              .then((resData) => resolve(resData))
              .catch((err) => reject(err))
          })
        },
        isRating: true
      },
      {
        title: 'Total Reviews Created',
        helper: 'The total number of reviews created for all offers.',
        startTime: dayjs(startTime).toISOString(),
        endTime: dayjs(endTime).toISOString(),
        dataRequest: async () => {
          return new Promise((resolve, reject) => {
            adminBusinessAnalyticsTotalCreated(
              dayjs(startTime).toISOString(),
              dayjs(endTime).toISOString(),
              jwt_token
            )
              .then((resData) => resolve(resData))
              .catch((err) => reject(err))
          })
        },
        isRating: true
      }
    ]
  }

  const fetchBusinessesAnalyticsDetails = (p = 1, st = startDate, et = endDate) => {
    setLoading(true)
    adminBusinessAnalyticsDetails(
      dayjs(st).toISOString(),
      dayjs(et).toISOString(),
      p,
      jwt_token
    )
      .then((resBody) => {
        setBusinesses({
          ...businesses.data,
          [p]: resBody
        })
        setLoading(false)
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    setReviewInsightGraphState(businessReviewsInsightData(startDate, endDate))
    fetchBusinessesAnalyticsDetails(1)
  }, [])

  return (
    <Stack
      sx={{
        margin: 1
      }}
    >
      <Stack direction="row" sx={{ marginBottom: 2 }}>
        {buttonsTimeline.map((button) => {
          return (
            <Button
              key={button.value}
              onClick={() => {
                if (button.key === 'customRangeSelector') {
                  setHasRange(true)
                } else {
                  setReviewInsightGraphState(businessReviewsInsightData(
                    dayjs().subtract(button.value, 'day').toISOString(),
                    dayjs().toISOString()
                  ))
                  fetchBusinessesAnalyticsDetails(
                    1,
                    dayjs().subtract(button.value, 'day').toISOString(),
                    dayjs().toISOString()
                  )
                  setStartDate(dayjs().subtract(button.value, 'day').format())
                  setEndDate(dayjs().toISOString())
                  setBusinesses({})
                  setHasRange(false)
                }
              }}
              variant="outlined"
              disableElevation
              sx={{
                marginRight: 1
              }}
            >
              {button.text}
            </Button>
          )
        })}
      </Stack>
      {hasRange && (
        <Fade in={hasRange} out={hasRange === false}>
          <Box sx={{ marginBottom: 2 }}>
            <Stack direction="row">
              <FormControl margin="normal">
                <Typography variant="h6">
                  Start date
                </Typography>
                <DatePicker
                  value={startDate}
                  onChange={(e) => setStartDate(dayjs(e).format())}
                  placeholder='mm/dd/yyyy'
                  // error={inputErrors.dateStart}
                />
              </FormControl>
              <FormControl margin="normal" sx={{ marginLeft: 1 }}>
                <Typography variant="h6">
                  End date
                </Typography>
                <DatePicker
                  value={endDate}
                  onChange={(e) => setEndDate(dayjs(e).format())}
                  placeholder='mm/dd/yyyy'
                  // error={inputErrors.dateStart}
                />
              </FormControl>
            </Stack>
            <Button
              onClick={() => {
                setReviewInsightGraphState(businessReviewsInsightData(
                  dayjs(startDate).toISOString(),
                  dayjs(endDate).toISOString()
                ))
                fetchBusinessesAnalyticsDetails(1)
              }}
              variant="contained"
              disableElevation
            >
              Set Dates
            </Button>
          </Box>
        </Fade>
      )}
      <Typography variant="h5" sx={{ marginTop: 2, marginBottom: 1 }} >
        Status for: {dayjs(startDate).format('MM/DD/YYYY')} - {dayjs(endDate).format('MM/DD/YYYY')}
      </Typography>
      <Stack direction="row" flexWrap="wrap">
        {reviewInsightGraphState.map((insight) => {
          return (
            <InsightGraphCard
              key={insight.title + insight.startTime}
              title={insight.title}
              helper={insight.helper}
              startTime={insight.startTime}
              endTime={insight.endTime}
              dataRequest={insight.dataRequest}
            />
          )
        })}
      </Stack>
      <Stack direction="column" sx={{ marginTop: 2, minHeight: '90vh' }}>
        <DataGrid
          rows={businesses[curPage] || []}
          getRowId={(r) => r.business_id}
          columns={[
            {
              field: 'business_name',
              headerName: 'Business Name',
              minWidth: 180,
              align: 'left'
            },
            {
              field: 'neighbourhood',
              headerName: 'Neighborhood',
              minWidth: 240,
              align: 'left'
            },
            {
              field: 'city',
              headerName: 'City',
              minWidth: 240,
              align: 'left'
            },
            {
              field: 'scans',
              headerName: 'Scans',
              minWidth: 140,
              align: 'left'
            },
            {
              field: 'review',
              headerName: 'Reviews',
              minWidth: 140,
              align: 'left'
            },
            {
              field: 'user_acquired',
              headerName: 'Users Acquired',
              minWidth: 140,
              align: 'left'
            },
            {
              field: 'rating',
              headerName: 'Rating',
              minWidth: 140,
              align: 'left',
              renderCell: ({ row }) => {
                return Number(row.rating).toFixed(1)
              }
            },
            {
              field: 'offer_created',
              headerName: 'New Offers Created',
              minWidth: 180,
              align: 'left'
            },
            {
              field: 'more_details',
              headerName: 'More Details',
              minWidth: 180,
              align: 'left',
              renderCell: ({ row }) => {
                return (
                  <Button
                    variant="contained"
                    onClick={() => openBusinessOfferDetails(
                      row.business_id,
                      dayjs(startDate).toISOString(),
                      dayjs(endDate).toISOString()
                    )}
                    disableElevation
                    size="small"
                  >
                    More
                  </Button>
                )
              }
            },
            {
              // field: 'business_id',
              headerName: 'View Public Page',
              minWidth: 180,
              align: 'left',
              renderCell: ({ row }) => {
                return (
                  <Button
                    variant="contained"
                    onClick={() => window.open(getUserDomainURL(`/b/${row.business_id}`))}
                    disableElevation
                    size="small"
                  >
                    Open
                  </Button>
                )
              }
            },
            {
              field: 'business_id',
              headerName: 'Login',
              minWidth: 180,
              align: 'left',
              renderCell: ({ row }) => {
                return (
                  <Button
                    color="primary"
                    variant='contained'
                    onClick={() => {
                      adminOneTimeLogin(row.business_id, jwt_token)
                        .then((body) => {
                          window.open(getBusinessDomainURL(`/admin-login?id=${body.id}&passcode=${body.passcode}`))
                        })
                        .catch((err) => console.log(err))
                    }}
                  >
                    Login
                  </Button>
                )
              }
            }
          ]}
          pageSize={100}
          components={{
            Toolbar: GridToolbar
          }}
          style={{
            overflowX: 'auto',
            alignContent: 'left',
            textAlign: 'left'
          }}
          onPageChange={(p) => {
            if (loading) {
              return
            }
            setCurPage(p)
            fetchBusinessesAnalyticsDetails(p)
          }}
          loading={loading}
          page={0}
          checkboxSelection
        />
      </Stack>
    </Stack>
  )
}

export default AllBusinessReviews
