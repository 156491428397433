import {
  Stack,
  Button,
  Typography
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

// importing components
import { UserContext } from '../../Contexts/UserContext'

// importing api
import { adminGetAll } from 'api/admin/api/getAll'

// importing helpers
import { getUserDomainURL } from 'helpers/url'

const reportTypes = [
  {
    text: 'Posts',
    value: 'report/posts',
    id: 'post_id',
    customField: [
      {
        field: 'openPost',
        headerName: 'View Post',
        minWidth: 140,
        renderCell: ({ row }) => {
          return (
            <Button
              onClick={() => {
                window.open(getUserDomainURL(`/p/${row.post_id}`))
              }}
            >
              Open
            </Button>
          )
        }
      }
    ]
  },
  {
    text: 'Groups',
    value: 'report/groups',
    id: 'group_id',
    customField: [
      {
        field: 'viewGroup',
        headerName: 'View Group',
        renderCell: ({ row }) => {
          return (
            <Button
              onClick={() => {
                window.open(getUserDomainURL(`/g/${row.group_id}`))
              }}
            >
              Open
            </Button>
          )
        }
      }
    ]
  },
  {
    text: 'Comments',
    value: 'report/comments',
    id: 'comment_id',
    customField: [

    ]
  },
  {
    text: 'Gatherings',
    value: 'report/gatherings',
    id: 'gathering_id',
    customField: [
      {
        field: 'viewGathering',
        headerName: 'View Gathering',
        minWidth: 240,
        renderCell: ({ row }) => {
          return (
            <Button
              onClick={() => {
                window.open(getUserDomainURL(`/gathering/${row.gathering_id}`))
              }}
            >
              Open
            </Button>
          )
        }
      }
    ]
  },
  {
    text: 'Businesses',
    value: 'report/business',
    id: 'business_id',
    customField: [
      {
        field: 'name',
        headerName: 'Business Name',
        minWidth: 240,
      },
      {
        field: 'viewBusiness',
        headerName: 'View Business',
        minWidth: 240,
        renderCell: ({ row }) => {
          return (
            <Button
              onClick={() => {
                window.open(getUserDomainURL(`/b/${row.business_id}`))
              }}
            >
              Open
            </Button>
          )
        }
      }
    ]
  }
]

const AllReports = (props) => {
  const { jwt_token } = useContext(UserContext)

  const [currentReportType, setCurrentReportType] = useState(0)
  const [loading, setLoading] = useState(true)
  const [reports, setReports] = useState({})
  const [curPage, setCurPage] = useState(1)

  const fetchBusinessesAnalyticsDetails = (p = 1) => {
    setLoading(true)
    adminGetAll(
      reportTypes[currentReportType].value,
      jwt_token,
      {
        page: p
      }
    )
      .then((resData) => {
        setReports({
          ...reports,
          [p]: resData.body
        })
        setLoading(false)
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchBusinessesAnalyticsDetails(1)
  }, [currentReportType])

  return (
    <Stack
      sx={{
        margin: 1
      }}
    >
      <Stack direction="row" sx={{ marginBottom: 2 }}>
        {reportTypes.map((button, index) => {
          return (
            <Button
              key={button.value}
              onClick={() => {
                setCurrentReportType(index)
                setReports({})
                setCurPage(1)
              }}
              variant="outlined"
              disableElevation
              sx={{
                marginRight: 1
              }}
            >
              {button.text}
            </Button>
          )
        })}
      </Stack>
      <Typography variant="h5" sx={{ marginTop: 2, marginBottom: 1 }} >
        Reported {reportTypes[currentReportType].text}
      </Typography>
      <Stack direction="column" sx={{ marginTop: 2, minHeight: '90vh' }}>
        <DataGrid
          rows={reports[curPage] || []}
          getRowId={(r) => r[reportTypes[currentReportType].id]}
          columns={[
            {
              field: reportTypes[currentReportType].id,
              headerName: 'ID',
              minWidth: 180,
              align: 'left'
            },
            {
              field: 'reason',
              headerName: 'Reason',
              minWidth: 240,
              align: 'left'
            },
            {
              field: 'count',
              headerName: 'Counts',
              minWidth: 80,
              align: 'left'
            },
            ...reportTypes[currentReportType].customField
          ]}
          pageSize={100}
          components={{
            Toolbar: GridToolbar
          }}
          style={{
            overflowX: 'auto',
            alignContent: 'left',
            textAlign: 'left'
          }}
          onPageChange={(p) => {
            if (loading) {
              return
            }
            setCurPage(p)
            fetchBusinessesAnalyticsDetails(p)
          }}
          loading={loading}
          page={0}
          checkboxSelection
        />
      </Stack>
    </Stack>
  )
}

export default AllReports
