import * as React from 'react'
import { Stack, Avatar, Typography, CircularProgress } from '@mui/material'

// importing component
import { UserContext } from '../../../Contexts/UserContext'

// importing ui
import WideModal from 'ui/Modals/WideModal'

// importing api
import { adminBusinessAnalyticsModal } from 'api/admin/api/business/analytics/details'

export interface BusinessOfferDetailsProps {
  businessId: string;
  startTime: string;
  endTime: string;
  isOpen: boolean;
  onClose: () => {}
}

const sampleOffers = [
  {
    offerTitle: 'Create a short video & get a free Coffee + 100 Nearcoins',
    couponCode: 'MAGGIE',
    nearcoins: 100,
    claimedBy: 345,
    scans: 232,
    reviews: 222,
    users: 89
  },
  {
    offerTitle: 'Create a short video & get a free Coffee + 100 Nearcoins',
    couponCode: 'MAGGIE',
    nearcoins: 100,
    claimedBy: 345,
    scans: 232,
    reviews: 222,
    users: 89
  },
  {
    offerTitle: 'Create a short video & get a free Coffee + 100 Nearcoins',
    couponCode: 'MAGGIE',
    nearcoins: 100,
    claimedBy: 345,
    scans: 232,
    reviews: 222,
    users: 89
  },
  {
    offerTitle: 'Create a short video & get a free Coffee + 100 Nearcoins',
    couponCode: 'MAGGIE',
    nearcoins: 100,
    claimedBy: 345,
    scans: 232,
    reviews: 222,
    users: 89
  },
  {
    offerTitle: 'Create a short video & get a free Coffee + 100 Nearcoins',
    couponCode: 'MAGGIE',
    nearcoins: 100,
    claimedBy: 345,
    scans: 232,
    reviews: 222,
    users: 89
  },
  {
    offerTitle: 'Create a short video & get a free Coffee + 100 Nearcoins',
    couponCode: 'MAGGIE',
    nearcoins: 100,
    claimedBy: 345,
    scans: 232,
    reviews: 222,
    users: 89
  }
]

const BusinessOfferDetails: React.FC<BusinessOfferDetailsProps> = (props) => {
  const { jwt_token } = React.useContext(UserContext)

  const [loading, setLoading] = React.useState(true)
  const [business, setBusiness] = React.useState(null)
  const [insights, setInsights] = React.useState(null)

  React.useEffect(() => {
    if (props.businessId && props.startTime && props.endTime) {
      adminBusinessAnalyticsModal(
        props.businessId,
        props.startTime,
        props.endTime,
        jwt_token
      )
        .then((data) => {
          setBusiness(data.BusinessDetails)
          setInsights(data.PageInsights)
          setLoading(false)
        })
        .catch((err) => console.log(err))
    }
  }, [props.businessId, props.startTime, props.endTime])

  return (
    <WideModal
      title="Business Details"
      isOpen={props.isOpen}
      onClose={() => {
        props.onClose()
        setLoading(true)
      }}
    >
      <Stack direction="column" sx={{ width: '100%', minHeight: '70vh' }}>
        {loading
          ? (
              <Stack direction="column" alignItems="center" justifyContent="center" sx={{ height: '70vh', width: '100%' }}>
                <CircularProgress />
              </Stack>
            )
          : (
              <>
                <Stack
                  direction="row"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  sx={{
                    marginTop: 1,
                    width: '100%'
                  }}
                >
                  <Stack direction="row" alignItems="flex-end">
                    <Avatar
                      alt="Business Icon"
                      src={business.business_cover}
                      sx={{
                        width: '100px',
                        height: '100px',
                        backgroundColor: 'black',
                        borderRadius: 4
                      }}
                    />
                    <Stack direction="column" sx={{ marginLeft: 2 }}>
                      <Typography variant="h4">
                        {business.name}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="link"
                        sx={{
                          cursor: 'pointer'
                        }}
                        onClick={() => window.open(business.custom_url)}
                      >
                        {business.custom_url}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row">
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        backgroundColor: '#f4efe7',
                        padding: 2,
                        borderRadius: 2,
                        marginRight: 1
                      }}
                    >
                      <Typography variant="body1">
                        Page Views
                      </Typography>
                      <Typography variant="body2">
                        {insights.views}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        backgroundColor: '#f4efe7',
                        padding: 2,
                        borderRadius: 2,
                        marginRight: 1
                      }}
                    >
                      <Typography variant="body1">
                        Reviews
                      </Typography>
                      <Typography variant="body2">
                        {insights.reviews}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        backgroundColor: '#f4efe7',
                        padding: 2,
                        borderRadius: 2,
                        marginRight: 1
                      }}
                    >
                      <Typography variant="body1">
                        Fans
                      </Typography>
                      <Typography variant="body2">
                        {insights.fans}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack direction="column" sx={{ marginTop: 3 }}>
                  <Typography variant="h5" sx={{ marginBottom: 1 }}>
                    Offers
                  </Typography>
                  {business.business_offers_data.map((offer, index) => {
                    return (
                      <Stack
                        key={index}
                        direction="row"
                        justifyContent="space-between"
                        sx={{
                          padding: 1,
                          height: '140px',
                          border: '0.5px solid black',
                          borderRadius: 2,
                          marginBottom: 2
                        }}
                      >
                        <Stack direction="row">
                          <Avatar
                            alt="Offer title"
                            src={offer.BusinessOfferCover}
                            sx={{
                              height: '100%',
                              width: '80px',
                              borderRadius: 1
                            }}
                          />
                          <Stack direction="column" sx={{ marginLeft: 2, marginRight: 2, marginTop: 1 }}>
                            <Typography variant="h4">
                              {offer.title}
                            </Typography>
                            <Typography
                              variant="caption"
                              color="primary"
                              sx={{
                                marginTop: 1,
                                cursor: 'pointer'
                              }}
                              onClick={() => window.open(offer.offer_short_link)}
                            >
                              Short link: {offer.offer_short_link}
                            </Typography>
                            {/* <Typography variant="caption" color="primary" sx={{ marginTop: 1 }}>
                              Coupon Code: {offer.couponCode}
                            </Typography> */}
                            <Typography variant="caption" color="primary">
                              Earn: {offer.nearcoins}
                            </Typography>
                            <Typography variant="caption" color="primary">
                              Active: {`${offer.is_active}`}
                            </Typography>
                            {/* <Typography variant="caption" color="primary">
                              Claimed By: {offer.claimedBy}
                            </Typography> */}
                          </Stack>
                        </Stack>
                        <Stack direction="row">
                          <Stack
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                              padding: 2,
                              border: '1px solid black',
                              borderRadius: 2,
                              width: '40px',
                              marginRight: 1
                            }}
                          >
                            <Typography variant="h6">
                              Scans
                            </Typography>
                            <Typography variant="h6">
                              {offer.Scans ? offer.Scans : 0}
                            </Typography>
                          </Stack>
                          <Stack
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                              padding: 2,
                              border: '1px solid black',
                              borderRadius: 2,
                              width: '40px',
                              marginRight: 1
                            }}
                          >
                            <Typography variant="h6">
                              Reviews
                            </Typography>
                            <Typography variant="h6">
                              {offer.Reviews ? offer.Reviews : 0}
                            </Typography>
                          </Stack>
                          <Stack
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                              padding: 2,
                              border: '1px solid black',
                              borderRadius: 2,
                              // width: '40px'
                            }}
                          >
                            <Typography variant="h6">
                              Users Acquired
                            </Typography>
                            <Typography variant="h6">
                              {offer.UserAcquired}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    )
                  })}
                </Stack>
              </>
            )}
      </Stack>
    </WideModal>
  )
}

export default BusinessOfferDetails
