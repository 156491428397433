import { adminGetOne } from 'api/admin/api/getAll'
import React, { useEffect, useContext, useCallback, useState } from 'react'
import { useParams } from 'react-router'
import { UserContext } from '../../Contexts/UserContext'

export default function GetOne ({ endpoints: { getOne } }) {
  const { id } = useParams()
  const [entry, setEntry] = useState(null)
  const { jwt_token } = useContext(UserContext)
  const getEntry = useCallback(async () => {
    if (!id) return
    const data = await adminGetOne(getOne?.(id), jwt_token)
  }, [])
  useEffect(() => {
    getEntry()
  }, [getEntry])
  return (
		<div>
			<a href="http://" target="_blank" rel="noopener noreferrer"></a>
		</div>
  )
}
