import * as React from 'react'
import {
  Stack,
  Typography,
  Box,
  Stepper,
  StepLabel,
  Step,
  StepContent
} from '@mui/material'
import { formatDistance, format } from 'date-fns'

// importing ui
import StandardModal from 'ui/Modals/StandardModal'

const openedTimingsMapper = [
  {
    title: 'Opened Email'
  },
  {
    title: 'Redirected to customized Landing Page'
  },
  {
    title: 'Clicked on Start My Trial'
  },
  {
    title: 'Successfully claimed the Business Page & Landed on Dashboard'
  }
]

export interface BusinessCampaignFlowProps {
  title: string;
  email: string;
  openedTimings: [string, string, string, string];
  isOpen: boolean;
  onClose: () => {}
}

const BusinessCampaignFlow: React.FC<BusinessCampaignFlowProps> = (props) => {
  const [curStep, setCurStep] = React.useState(0)

  const selectActiveIndex = () => {
    let index = -1
    for (let i = 0; i < props.openedTimings.length; i++) {
      if (new Date(props.openedTimings[i]).getFullYear() <= 2) {
        break
      }
      index = i
    }
    return index
  }

  React.useEffect(() => {
    if (props.isOpen) {
      setCurStep(selectActiveIndex())
    }
  }, [props.isOpen])

  return (
    <StandardModal
      isOpen={props.isOpen}
      onClose={() => props.onClose()}
    >
      <Stack direction="column" sx={{ width: '100%' }}>
        <Typography variant="body1">
          Business Name: {props.title}
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 1 }}>
          Email: {props.email}
        </Typography>
        <Box sx={{ marginTop: 1 }}>
          <Stepper
            activeStep={curStep}
            orientation="vertical"
          >
            {openedTimingsMapper.map((step, index) => (
              <Step
                key={step.title}
                onClick={() => {
                  if (selectActiveIndex() > index) {
                    setCurStep(index)
                  }
                }}
                sx={{
                  cursor: 'pointer !important'
                }}
                completed={selectActiveIndex() >= index}
                expanded={selectActiveIndex() >= index}
              >
                <StepLabel>
                  {step.title}
                </StepLabel>
                <StepContent>
                  <Typography variant="body2">At {format(new Date(props.openedTimings[index]), 'kk:mm')} on {format(new Date(props.openedTimings[index]), 'PPP')}</Typography>
                  {index > 0 && (
                    <Typography variant="body2">
                      After {formatDistance(new Date(props.openedTimings[index]), new Date(props.openedTimings[index - 1]))} from last event
                    </Typography>
                  )}
                </StepContent>
              </Step>
            ))}
          </Stepper>
      </Box>
      </Stack>
    </StandardModal>
  )
}

export default BusinessCampaignFlow
