import { createTheme, adaptV4Theme } from '@mui/material/styles'

const colorPallete = {
  '': {
    main: '#3A8F78',
    light: '#6D9674',
    dark: '#0c3a2d'
  },
  primary: {
    main: '#1A6751',
    light: '#1A6751',
    alt: '#1A6751'
  },
  secondary: {
    main: '#D76238',
    light: '#D76238',
    alt: '#D76238'
  },
  ghost: {
    main: '#828282',
    light: '#828282',
    dark: '#828282',
    contrastText: '#fff'
  },
  link: {
    main: '#007AFF',
    light: '#007AFF',
    dark: '#007AFF',
    alt: '#007AFF'
  },
  warning: {
    main: '#DF693C',
    light: '#DF693C',
    dark: '#DF693C',
    contrastText: '#fff'
  },
  error: {
    main: '#CA4A4A',
    light: '#CA4A4A',
    dark: '#CA4A4A',
    contrastText: '#fff'
  },
  success: {
    main: '#178747',
    light: '#178747',
    dark: '#178747',
    contrastText: '#fff'
  },
  grey: {
    50: '#fcfcfc'
  }
}

const customTheme = createTheme(adaptV4Theme({
  palette: {
    ...colorPallete
  },
  typography: {
    fontFamily: [
      'avenir next',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    h1: {
      fontSize: 34,
      fontWeight: 600
    },
    h2: {
      fontSize: 28,
      fontWeight: 600
    },
    h3: {
      fontSize: 24,
      fontWeight: 600
    },
    h4: {
      fontSize: 20,
      fontWeight: 600
    },
    h5: {
      fontSize: 17,
      fontWeight: 600
    },
    h6: {
      fontSize: 15,
      fontWeight: 600
    },
    body1: {
      fontSize: 18,
      fontWeight: 400
    },
    body2: {
      fontSize: 16,
      fontWeight: 400
    }
  },
  overrides: {
    MuiSnackbar: {
      info: {
        backgroundColor: 'white'
      },
      root: {
        backgroundColor: 'white'
      }
    },
    MuiCard: {
      root: {
        borderRadius: '20px'
      }
    },
    MuiIconButton: {
      colorPrimary: {
        backgroundColor: colorPallete.primary.main,
        color: 'white',
        '&:hover': {
          backgroundColor: `${colorPallete.primary.main}de`
        }
      }
    },
    MuiModal: {
      root: {
        outline: 'none'
      },
      '&&focused': {
        outline: 'none'
      }
    },
    MuiButton: {
      root: {
        height: '45px',
        borderRadius: '10px',
        textTransform: 'none',
        paddingInline: '20px',
        textAlign: 'center',
        '& .MuiButton-label': {
          display: 'inline',
          lineHeight: 'normal',
          textDecoration: 'none'
        }
      }
    }
  }
}))

export default customTheme
