import axios from 'axios'

/**
 * @function approxGeneral Gets the number of nearcasts created
 * @param {string} jwt - the JWT token of the user (accessed through UserContext)
 * @returns {Promise} Promise object represents
 */

export const approxGeneral = (jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/admin/api/approx/general-analytics`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    )
      .then((res) => resolve(res.data.body))
      .catch((err) => reject(err))
  })
}
