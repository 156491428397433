import axios from 'axios'

/**
 * @function getGeoJson Used to get Geo Data
 * @param {string} id - id of the gathering
 * @returns {Promise} Promise object represents
 */
export const getGeoJson = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/get/geojson/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err)
      })
  })
}
