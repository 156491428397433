import { Button } from '@mui/material'
import React, { useState } from 'react'
import EditCustomURL from '../modals/EditCustomURL'

const EditURLBtn = ({ data }) => {
  const [open, setOpen] = useState(false)
  return (
		<div>
			<Button variant="outlined" onClick={() => setOpen(true)}>
				Edit Custom URL
			</Button>
			<EditCustomURL
				open={open}
				onClose={() => setOpen(false)}
				data={data}
			/>
		</div>
  )
}

export default EditURLBtn
