import React, { useContext } from 'react'
import { UserContext } from '../../Contexts/UserContext'
import NearcastLogo from 'ui/Icons/nearcast-logo.png'
import { useHistory, useLocation } from 'react-router-dom'
import { CardContent, Grid, Card, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import './Login.css'
import GoogleSignin from '../../Components/GoogleSignin/GoogleSignin'
import { adminLoginPost } from 'api/admin/api/login.js'

function Login (props) {
  const { login } = useContext(UserContext)
  const history = useHistory()
  const { state } = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const loginAPI = async (token) => {
    adminLoginPost(token)
      .then((res) => {
        if (res) {
          login({
            jwt_token: res.body.token,
            user: res.body.profile
          })
          if (state) history.push(state.pathname)
          else history.push('/')
        }
      })
      .catch((err) => {
        enqueueSnackbar(
          err.response?.data?.msg ??
						'There was some error. Please try again!',
          { variant: 'error' }
        )
      })
  }
  return (
		<div className="login-page">
			<Card className="login-card">
				<CardContent className="">
					<form id="login-form">
						<Grid container spacing={2} className="">
							<Grid item xs={12}>
								<img
									src={NearcastLogo}
									style={{ marginBottom: '20px' }}
									alt="Nearcast Logo"
									width="250px"
									draggable="false"
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography
									gutterBottom
									variant="h5"
									style={{
									  fontWeight: 'bold',
									  marginBottom: '10px'
									}}
								>
									Login to Access the Admin Portal
								</Typography>
							</Grid>

							<Grid item xs={12} className="btn-grid"></Grid>
						</Grid>
						<GoogleSignin
							onSuccess={loginAPI}
							onError={() =>
							  enqueueSnackbar('Please try again', {
							    variant: 'error'
							  })
							}
						/>
					</form>
				</CardContent>
			</Card>
		</div>
  )
}

export default Login
