import React from 'react'

function Nearcast ({ htmlColor, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 717.05 133.64"
      {...props}
    >
      <defs></defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            style={{ fill: htmlColor }}
            d="M168.58,75.83c0-20.93,12.43-31.71,30.95-31.71s31,10.65,31,31.71v26.58a6,6,0,0,1-6,6h0a6,6,0,0,1-6-6V75.83c0-13.57-7.48-20.55-18.9-20.55s-18.9,7-18.9,20.55v26.58a6,6,0,0,1-6,6h0a6,6,0,0,1-6-6Z"
          />
          <path
            style={{ fill: htmlColor }}
            d="M240.38,77.1c0-19,14-33,33.75-33s33.49,14,33.49,33h0a4.44,4.44,0,0,1-4.44,4.44H254.46a1.15,1.15,0,0,0-1.14,1.37c2.23,10.3,10.35,16,20.81,16,7.29,0,12.64-2,16.32-6.42a4.39,4.39,0,0,1,3.37-1.57h3.43a4.45,4.45,0,0,1,3.47,7.22c-5.9,7.44-15.3,11.93-26.59,11.93C254.34,110.08,240.38,96.13,240.38,77.1Zm52.93-6.34a1.24,1.24,0,0,0,1.16-1.64c-2.88-9-10.76-13.84-20.34-13.84-9.82,0-17.38,5-20.22,13.84a1.24,1.24,0,0,0,1.16,1.64Z"
          />
          <path
            style={{ fill: htmlColor }}
            d="M315,77.6c0-19.66,13.95-33.48,33.87-33.48,19.66,0,33.36,13.44,33.36,33.48v25.31a5.52,5.52,0,0,1-5.52,5.52h0a5.52,5.52,0,0,1-5.51-5.52V94.73c-4.19,10.15-13.45,15.35-24.36,15.35C330.45,110.08,315,98.28,315,77.6Zm55.31-.5c0-12.69-8.63-21.82-21.57-21.82s-21.56,9.13-21.56,21.82,8.62,21.82,21.56,21.82S370.29,89.78,370.29,77.1Z"
          />
          <path
            style={{ fill: htmlColor }}
            d="M442,71.57a6,6,0,0,1-5.81-4.93c-1.53-8-7-11.36-14.54-11.36-9,0-15,4.69-15,16.24v30.89a6,6,0,0,1-6,6h0a6,6,0,0,1-6-6V71.52c0-18.53,10.91-27.4,27-27.4,13.83,0,23.86,6.64,26.31,20.52a6,6,0,0,1-6,6.93Z"
          />
          <path
            style={{ fill: htmlColor }}
            d="M454.27,77.1c0-19,14-33,33.74-33,12.95,0,23.36,6,28.88,15.5a5.86,5.86,0,0,1-5,8.85h0a5.8,5.8,0,0,1-5-2.89c-3.58-6.33-10.28-10.3-18.81-10.3-12.94,0-21.56,9.13-21.56,21.82S475.07,98.92,488,98.92c8.53,0,15.23-4,18.81-10.3a5.79,5.79,0,0,1,5-2.9h0a5.87,5.87,0,0,1,5,8.86c-5.52,9.49-15.93,15.5-28.88,15.5C468.22,110.08,454.27,96.13,454.27,77.1Z"
          />
          <path
            style={{ fill: htmlColor }}
            d="M527.85,77.6c0-19.66,13.95-33.48,33.87-33.48,19.66,0,33.36,13.44,33.36,33.48v25.31a5.52,5.52,0,0,1-5.52,5.52h0a5.52,5.52,0,0,1-5.51-5.52V94.73c-4.19,10.15-13.45,15.35-24.36,15.35C543.32,110.08,527.85,98.28,527.85,77.6Zm55.31-.5c0-12.69-8.63-21.82-21.57-21.82S540,64.41,540,77.1s8.62,21.82,21.56,21.82S583.16,89.78,583.16,77.1Z"
          />
          <path
            style={{ fill: htmlColor }}
            d="M612.66,90.29h.64a5.61,5.61,0,0,1,5,3.22c1.75,3.44,6.18,5.66,13,5.66,9.13,0,13.83-3.93,13.83-8.88,0-6-6.6-6.72-14.47-7.74-10.91-1.39-24.1-3.42-24.1-17.76,0-12.3,9.64-20.67,24.61-20.67,10.71,0,18.5,4.35,22.16,10.77a5.76,5.76,0,0,1-5.09,8.51h-.3a5.9,5.9,0,0,1-5.14-3.16C641,57.1,637.06,55,631.24,55c-8,0-12.56,3.81-12.56,8.76,0,6,6.6,6.59,14.33,7.61,10.91,1.39,24.23,3.42,24.23,17.76,0,12.68-10.27,20.93-26,20.93-11.42,0-19.76-4.41-23.62-11.19A5.77,5.77,0,0,1,612.66,90.29Z"
          />
          <path
            style={{ fill: htmlColor }}
            d="M711.07,84.71A6,6,0,0,1,716.92,92c-2.54,12.12-11.75,18.1-24.41,18.1-15,0-25.12-8.37-25.12-25.37V29.59a6,6,0,0,1,6-6h0a6,6,0,0,1,6,6V45.76h29A5.52,5.52,0,0,1,714,51.28h0a5.52,5.52,0,0,1-5.52,5.52h-29V84.71c0,9.89,5.21,14.21,13.07,14.21,6.54,0,11.32-2.9,12.75-9.63a5.91,5.91,0,0,1,5.81-4.58Z"
          />
          <path
            style={{ fill: htmlColor }}
            d="M47.06,52c0-14.64,8.7-22.19,21.66-22.19S90.38,37.24,90.38,52V69.5a5.28,5.28,0,0,1-5.28,5.28h0a5.27,5.27,0,0,1-5.28-5.28V52c0-8.34-4.44-12.34-11.1-12.34S57.63,43.63,57.63,52V69.5a5.28,5.28,0,0,1-5.28,5.28h0a5.28,5.28,0,0,1-5.29-5.28Z"
          />
          <path
            style={{ fill: htmlColor }}
            d="M68.72,104.93A40.91,40.91,0,0,1,39.36,92.62a5.89,5.89,0,1,1,8.4-8.27,29.4,29.4,0,0,0,41.92,0,5.89,5.89,0,1,1,8.4,8.26A40.85,40.85,0,0,1,68.72,104.93Z"
          />
          <path
            style={{ fill: htmlColor }}
            d="M52.5,133.4a6,6,0,0,1-1.56-.21A68.74,68.74,0,0,1,51.18.33,6,6,0,0,1,54.26,12a56.65,56.65,0,0,0-.2,109.48A6,6,0,0,1,52.5,133.4Z"
          />
          <path
            style={{ fill: htmlColor }}
            d="M84,133.64a6,6,0,0,1-1.47-11.91,56.64,56.64,0,0,0,.21-109.82,6,6,0,0,1,3-11.72,68.74,68.74,0,0,1-.24,133.27A5.74,5.74,0,0,1,84,133.64Z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Nearcast
